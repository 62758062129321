import styled from 'styled-components';
import useInput from '../../hooks/useInput';
import { registerEmail } from '../../api/app';
import { useCallback, useEffect, useState } from 'react';
import useModal from '../../hooks/useModal';
import CompleteModal from './Complete';
import { cursorPointerStyle } from '../../styles';
import { useTranslation } from 'react-i18next';
const AWrapper = styled.a`
  opacity: ${(props) => (props.bgDisable ? 1 : 0.3)} !important;

  &:hover {
    opacity: 0.8;
  }
  cursor: pointer;
`;
const reg = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

const NotifyModal = ({ closeHandler }) => {
  const [email, onChangeEmail, setEmail] = useInput('');
  const [loading, setLoading] = useState(false);
  const { ModalPortal, openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const [errorHandle, setErrorHandle] = useState({
    error: false,
    text: '',
  });

  const allCloseModal = () => {
    closeHandler();
    closeModal();
  };

  const onClickHandler = async () => {
    if (reg.test(email)) {
      try {
        setLoading(true);
        await registerEmail({ email });
        openModal();
        setErrorHandle({
          error: false,
          text: '',
        });
        setLoading(false);
      } catch (err) {
        alert('에러가 발생했습니다.');
        setLoading(false);
      }
    } else {
      setErrorHandle({
        error: true,
        text: '입력형식이 올바르지 않습니다. 확인 후 다시 입력해 주세요',
      });
    }
  };

  const handleUserKeyPress = useCallback((event) => {
    const { keyCode } = event;
    if (keyCode === 27 && !loading) {
      closeHandler();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <>
      <div className="popup-wrap" style={{ display: 'block', overflowY: 'auto' }}>
        <div className="dim" />
        <div className="pop-type01 pop-notice" id="openNotice">
          <strong className="ico-notice">{t(`modal.email_title`)}</strong>
          <p>{t(`modal.email_desc`)}</p>
          <div className="input">
            <input type="text" value={email} onChange={onChangeEmail} style={{ outline: 'none' }} />
            {errorHandle?.error && <p className="txt-notice">{errorHandle?.text}</p>}
          </div>
          <AWrapper bgDisable={reg.test(email)} className="btn-line" onClick={onClickHandler}>
            {t(`modal.email_notify`)}
          </AWrapper>
          <a style={cursorPointerStyle} onClick={closeHandler} className="pop-close">
            {t(`common.back`)}
          </a>
        </div>

        <ModalPortal>
          <CompleteModal closeModal={allCloseModal} />
        </ModalPortal>
      </div>
    </>
  );
};

export default NotifyModal;
