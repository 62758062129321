import { cursorPointerStyle } from '../../styles';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { pathnameInfoAtom } from '../../store/atom';

const RequireLoginModal = ({ closeModal, skip = false }) => {
  const history = useHistory();
  const [pathnameInfo, setPathnameInfo] = useRecoilState(pathnameInfoAtom);

  const closeBtnHandler = () => {
    if (skip) {
      history.replace('/');
      closeModal();
      setPathnameInfo({ text: 'Home', route: '/', isHashRoute: false });
      return;
    }
    closeModal();
  };

  return (
    <>
      <div className="popup-wrap" style={{ display: 'block' }}>
        <div className="dim" />

        <div className="pop-type01 connect-error">
          <p className="ico-error">로그인이 필요합니다.</p>
          <a style={cursorPointerStyle} onClick={closeBtnHandler} className="btn-round">
            돌아가기
          </a>
        </div>
      </div>
    </>
  );
};

export default RequireLoginModal;
