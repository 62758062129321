import { useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import styled from 'styled-components';
import { cursorPointerStyle } from '../../../styles';
const DivWrapper = styled.div`
  font-family: 'Spoqa Han Sans Neo', 'Josefin Sans', 'Nanum Myeongjo', 'sans-serif';
  display: flex;
  flex-direction: column;
  input {
    text-indent: 10px !important;
    border: 1px solid #ddd !important;
  }
  .address {
    width: 300px;
  }
  a {
    width: 10% !important;
    margin-top: 10px !important;
  }

  .mt-10 {
    margin-top: 10px;
  }
`;

const DivContainer = styled.div`
  .default-input {
    width: 300px !important;
  }
`;

const ShippingInformationBox = ({ register, openModal, address, setAddress, copyValue, errors }) => {
  return (
    <>
      <div className="exchange-box">
        <h3 className="small-tit">배송지 정보</h3>
        <a className="btn-round small" style={cursorPointerStyle} onClick={copyValue}>
          신청자와 동일함
        </a>
        <div className="cont cont-table">
          <table>
            <colgroup>
              <col width="160px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th>이름</th>
                <td className="input">
                  <input
                    className="default-input"
                    type="text"
                    {...register('shippingName', {
                      required: '필수정보입니다.',
                      pattern: {
                        value: /^[가-힣a-zA-Z]+$/,
                        message: '이름에 특수문자를 사용할 수 없습니다.',
                      },
                    })}
                  />
                  <p className="notice ">{errors?.shippingName?.message}</p>
                </td>
              </tr>
              <tr>
                <th>배송 주소</th>
                <td>
                  {address ? (
                    <DivWrapper>
                      <em className="address">{address}</em>
                      <input
                        className="address default-input"
                        type="text"
                        placeholder="상세주소를 입력하세요."
                        {...register('detailedAddress', {
                          required: '필수정보입니다.',
                          // pattern: {
                          //   value: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
                          //   message: '이메일 형식이 잘못되었습니다. 다시 입력해주세요.',
                          // },
                        })}
                      />
                      <div>
                        <a onClick={openModal} className="btn-round small" style={cursorPointerStyle}>
                          주소 찾기
                        </a>
                        <p className="notice mt-10">{errors?.detailedAddress?.message}</p>
                      </div>
                    </DivWrapper>
                  ) : (
                    <a onClick={openModal} className="btn-round small" style={cursorPointerStyle}>
                      주소 찾기
                    </a>
                  )}
                </td>
              </tr>
              <tr>
                <th>연락처</th>
                <td className="input">
                  <input
                    className="default-input "
                    type="number"
                    {...register('shippingPhoneNumber', {
                      valueAsNumber: true,
                      required: '필수정보입니다.',
                      pattern: {
                        value: /^[0-9]+$/,
                        message: '숫자만 입력하세요.',
                      },
                    })}
                  />
                  <p className="notice">{errors?.shippingPhoneNumber?.message}</p>
                </td>
              </tr>
              <tr>
                <th>배송 요청 사항</th>
                <td className="input">
                  <span className="request">
                    <input
                      type="text"
                      placeholder="* 공동현관 비밀번호, 택배 맡길 장소 등 특이사항이 있다면 남겨주세요."
                      {...register('shippingRequest', {
                        // required: '필수정보입니다.',
                        // pattern: {
                        //   value: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
                        //   message: '이메일 형식이 잘못되었습니다. 다시 입력해주세요.',
                        // },
                      })}
                    />
                    <p className="notice">{errors?.shippingRequest?.message}</p>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ShippingInformationBox;
