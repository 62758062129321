import { cursorPointerStyle } from '../../styles';
import { useTranslation } from 'react-i18next';

const MembershipPageNftLists = ({
  idx,
  name,
  grade,
  black,
  meterial,
  edge,
  url,
  paparecipe,
  papaground,
  brandnew,
  sale,
  description,
  tokenURI,
  limit,
  aboveground,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <li>
        <figure>
          <img src={url} />
        </figure>
        <div className="text">
          <strong className="list-tit">{name}</strong>
          <div className="flex">
            <span>Amount</span>
            <strong>
              <em>{limit}</em>
            </strong>
          </div>
          <p className="desc"></p>
          <ul>
            <li>
              <span>파파레서피, ABG</span>
              <strong>기본혜택 {paparecipe.toString()}배</strong>
            </li>
            <li>
              <span>파파그라운드</span>
              <strong>기본혜택 {papaground.toString()}배</strong>
            </li>
            <li>
              <span>어보브그라운드</span>
              <strong>{aboveground}</strong>
            </li>
            {brandnew && (
              <li>
                <span>파파레서피 신제품</span>
                <strong>자동 구독</strong>
              </li>
            )}
          </ul>
          {/*<p className="desc">파파레서피와 ABG에 특별한 기여를 해주신 분들께 제공되며, 구매나 재판매가 불가합니다.</p>*/}
          {/*<p className="alert">혜택 미공개</p>*/}
        </div>
        {sale ? (
          <a className="btn-purchase" style={cursorPointerStyle}>
            {t(`common.mint`)}
          </a>
        ) : (
          <a style={{ opacity: 0.5, cursor: 'not-allowed' }} className="btn-purchase">
            {t(`common.mint`)}
          </a>
        )}
      </li>
    </>
  );
};

export default MembershipPageNftLists;
