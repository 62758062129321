import type_list_img from 'assets/images/img_typelist02.png';
import btn_tag_logo from 'assets/images/btn_tab-logo.png';
import left_img from '../../assets/images/bg_arrow-prev.png';
import right_img from '../../assets/images/bg_arrow-next.png';
import styled from 'styled-components';
import Slider from 'react-slick';
import MembershipDetailSlider from '../MembershipDetailSlider';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { getMyInfo } from '../../api/account';
import useModal from '../../hooks/useModal';
import RequireLoginModal from '../Modal/RequireLogin';
import { useEffect } from 'react';
import PapaRecipeTab from '../PaParecipeTab';
import AboveTab from '../AboveTab';
import GroundTab from '../groundTab';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import { isCorrectNetworkAtom } from '../../store/atom';
import { useTranslation } from 'react-i18next';
const SliderContainer = styled(Slider)`
  margin: 0 auto !important;
  //width: 372px !important;

  //.slick-current {
  //  padding-left: 80px !important;
  //}

  @media (max-width: 1280px) {
    .slick-slide {
      display: flex;
      justify-content: center;
    }
  }
`;

const sliderDummyData = [
  {
    img: type_list_img,
    type: 'Type A+',
    title: 'SHERPA CLUB A+ MEMBER #24',
    desc: '구매 가능한 가장 높은 등급의 NFT입니다.',
  },
];

export const UlContainer = styled.ul`
  overflow: none !important;
`;
const PreviousArrowImage = styled.img`
  width: 24px;
  position: relative;
  top: 326px;
  left: -72px;
  cursor: pointer;

  @media (max-width: 1280px) {
    display: none;
  }
`;
const NextArrowImage = styled.img`
  position: relative;
  width: 24px;
  bottom: 293px;
  cursor: pointer;
  z-index: 100000;
  left: 420px;
  cursor: pointer;

  @media (max-width: 1280px) {
    display: none;
  }
`;
function LeftNavButton(props) {
  const { className, style, onClick } = props;
  return (
    <div className="slick-arrow" style={{ ...style, display: 'block' }} onClick={onClick}>
      <PreviousArrowImage src={left_img} alt="arrow_left" />
    </div>
  );
}

function RightNavButton(props) {
  const { className, style, onClick } = props;
  return (
    <div className="slick-arrow" style={{ ...style, display: 'block' }} onClick={onClick}>
      <NextArrowImage src={right_img} alt="arrow_left" />
    </div>
  );
}
const MembershipDetailPage = () => {
  const history = useHistory();
  const papaRecipeMatch = useRouteMatch('/shop/membership/:id/papa-recipe');
  const aboveMatch = useRouteMatch('/shop/membership/:id/above');
  const groundMatch = useRouteMatch('/shop/membership/:id/ground');
  const isCorrectNetwork = useRecoilValue(isCorrectNetworkAtom);
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { ModalPortal, openModal, closeModal } = useModal();
  const bootstrap = async () => {
    try {
      await getMyInfo();
    } catch (err) {
      openModal();
    }
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <LeftNavButton />,
    nextArrow: <RightNavButton />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    bootstrap();
  }, []);

  return (
    <>
      <article>
        {isCorrectNetwork && <p className="network-error">{t(`common.change_metamask_network`)} </p>}
        <section className="shop-cont">
          <div className="membership-list-wrap">
            <Link to="/shop/membership" className="back">
              Membership NFT
            </Link>
            <div className="slide-wrap">
              <UlContainer className="type-list">
                <SliderContainer {...settings}>
                  {sliderDummyData.map((item, i) => (
                    <MembershipDetailSlider key={i} {...item} />
                  ))}
                </SliderContainer>
              </UlContainer>
            </div>
            <ul className="tab">
              <li className={classNames({ curr: papaRecipeMatch !== null })}>
                <Link to={`${url}/papa-recipe`}>
                  <img src={btn_tag_logo} alt="" />
                </Link>
              </li>
              <li className={classNames({ curr: aboveMatch !== null })}>
                <Link to={`${url}/above`}>Above Ground</Link>
              </li>
              <li className={classNames({ curr: groundMatch !== null })}>
                <Link to={`${url}/ground`}>Papa Ground</Link>
              </li>
            </ul>
            <div className="cont-box">
              <Switch>
                <Route exact path={`${url}/papa-recipe`}>
                  <PapaRecipeTab />
                </Route>

                <Route exact path={`${url}/above`}>
                  <AboveTab />
                </Route>

                <Route exact path={`${url}/ground`}>
                  <GroundTab />
                </Route>
              </Switch>
            </div>
          </div>
        </section>
        <ModalPortal>
          <RequireLoginModal closeModal={closeModal} skip={true} />
        </ModalPortal>
      </article>
      <footer>
        <p className="copy">©2022 COSTORY CO., LTD. All rights reserved.</p>
      </footer>
    </>
  );
};

export default MembershipDetailPage;
