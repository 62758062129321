import img_about from '../../assets/images/img_about.jpg';
import img_about_m from '../../assets/images/img_about-m.jpg';
import img_about_papa from '../../assets/images/img_about-papa.jpg';
import img_about_product from '../../assets/images/img_about-product.png';
import img_about_product_m from '../../assets/images/img_about-product-m.png';
import img_about_abg from '../../assets/images/img_about-abg.jpg';
import img_about_abg_m from '../../assets/images/img_about-abg-m.jpg';
import img_about_photo from '../../assets/images/img_about-photos.jpg';
import img_about_photo_m_1 from '../../assets/images/img_about-photos-m01.jpg';
import img_about_photo_m_2 from '../../assets/images/img_about-photos-m02.jpg';
import img_about_photo_m_3 from '../../assets/images/img_about-photos-m03.jpg';
import img_about_photo_m_4 from '../../assets/images/img_about-photos-m04.jpg';
import img_about_photo_m_5 from '../../assets/images/img_about-photos-m05.jpg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { cursorPointerStyle } from '../../styles';
import useModal from '../../hooks/useModal';
import InfoModal from '../../components/Modal/Info';
import { useRecoilValue } from 'recoil';
import { isCorrectNetworkAtom, isMatchAccountAtom } from '../../store/atom';
import { AccountErrorText, PContainer } from '../Home';
const PWrapper = styled.p`
  border-top: 1px solid #7a928c;
`;

const AboutPage = () => {
  const { t } = useTranslation();
  const isMatchAccount = useRecoilValue(isMatchAccountAtom);

  const { ModalPortal, openModal, closeModal } = useModal();
  const isCorrectNetwork = useRecoilValue(isCorrectNetworkAtom);
  return (
    <>
      <article>
        {isCorrectNetwork && <PContainer className="network-error">{t(`common.change_metamask_network`)}</PContainer>}
        {isMatchAccount && <AccountErrorText>{t(`home.header_account_error`)}</AccountErrorText>}

        <section className="about-kv kv01">
          <div className="inner-box">
            <div className="text">
              <span>{t(`about.main_title`)}</span>
              <strong>
                {t(`about.main_title_sub_01`)}
                <i className="br">{t(`about.main_title_sub_02`)}</i>
              </strong>
            </div>
          </div>
        </section>

        <section className="about01">
          <div className="effect-box" />
          <h2 className="tit">
            {t(`about.main_desc_01`)}
            <i className="br">{t(`about.main_desc_02`)}</i>
          </h2>
          <div className="desc">
            <p>{t(`about.main_desc_03`)}</p>
            <p>{t(`about.main_desc_04`)}</p>
          </div>
          <div className="about01-1">
            <figure>
              <img src={img_about_papa} alt="" />
              <figcaption>
                {t(`about.main_desc_05`)}
                <i className="br">{t(`about.main_desc_06`)}</i>
              </figcaption>
            </figure>
            <div className="desc">
              <p>
                {t(`about.main_desc_07`)}
                <i className="br">{t(`about.main_desc_08`)}</i>
                {t(`about.main_desc_08`)}
              </p>
              <strong>{t(`about.main_desc_09`)}</strong>
            </div>
          </div>
          <div className="about01-2">
            <figure>
              <span className="pc">
                <img src={img_about_product} alt="" />
              </span>
              <span className="mobile">
                <img src={img_about_product_m} alt="" />
              </span>
            </figure>
            <div className="desc">
              <strong>{t(`about.main_desc_11`)}</strong>
              <p>{t(`about.main_desc_12`)}</p>
              <p>
                {t(`about.main_desc_13`)}
                <i className="br">{t(`about.main_desc_14`)}</i>
              </p>
            </div>
            <a
              style={cursorPointerStyle}
              href="https://paparecipe.com"
              target="_blank"
              rel="noreferrer noopener"
              className="btn-line green"
            >
              {t(`about.main_desc_15`)}
            </a>
          </div>
        </section>

        <section className="about-kv kv02">
          <div className="inner-box">
            <div className="text">
              <h2 className="tit white eng">
                ABOVE <i className="br">GROUND</i>
              </h2>
              <strong>{t(`about.main_desc_16`)}</strong>
              <p>
                {t(`about.main_desc_17`)} <i className="br">{t(`about.main_desc_18`)}</i>
                {t(`about.main_desc_19`)}
              </p>
            </div>
            {/*<figure>*/}
            {/*<span className="pc">*/}
            {/*  <img src={img_about_abg} alt="" />*/}
            {/*</span>*/}
            {/*  <span className="mobile">*/}
            {/*  <img src={img_about_abg_m} alt="" />*/}
            {/*</span>*/}
            {/*</figure>*/}
          </div>
        </section>
        <section className="about02">
          <div className="effect-box" />
          <figure>
            <span className="pc">
              <img src={img_about_photo} alt="" />
            </span>
            <span className="mobile">
              <img src={img_about_photo_m_1} alt="" />
              <img src={img_about_photo_m_2} alt="" />
              <img src={img_about_photo_m_3} alt="" />
              <img src={img_about_photo_m_4} alt="" />
              <img src={img_about_photo_m_5} alt="" />
            </span>
          </figure>
          <div className="desc">
            <p>
              {t(`about.main_desc_20`)}
              <i className="br">{t(`about.main_desc_21`)}</i>
              {t(`about.main_desc_22`)}
            </p>
            <p>{t(`about.main_desc_23`)}</p>
          </div>
          <a onClick={openModal} style={cursorPointerStyle} className="btn-line green">
            {t(`about.main_desc_24`)}
          </a>
        </section>
      </article>
      <footer>
        <PWrapper className="copy">©2022 COSTORY CO., LTD. All rights reserved.</PWrapper>
      </footer>

      <ModalPortal>
        <InfoModal closeModal={closeModal} text={t(`about.modal_info_01`)} />
      </ModalPortal>
    </>
  );
};

export default AboutPage;
