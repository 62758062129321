import useInput from '../../hooks/useInput';
import { useState } from 'react';
import { cursorPointerStyle } from '../../styles';

const PresentModal = ({ onHandler, closeModal }) => {
  const [address, onChangeAddress, setAddress] = useInput('');
  const [agreed, setAgreed] = useState(false);
  return (
    <>
      <div className="popup-wrap" style={{ display: 'block' }}>
        <div className="dim" />
        <div className="pop-type01 pop-gift" id="giveGift">
          <strong className="ico-gift">선물하기</strong>
          <p>선물을 받으실 분의 이더리움(가급적 메타마스크) 주소를 정확히 입력하세요.</p>
          <div className="input">
            <input value={address} onChange={onChangeAddress} type="text" placeholder="Enter Wallet Address" />
            {/*<p className="txt-notice">*오류 : 잘못된 주소형식입니다. 확인 후 다시 입력해주세요.</p>*/}
          </div>
          <div className="check-box">
            <input
              type="checkbox"
              id="agree"
              checked={agreed}
              onClick={() => {
                setAgreed(!agreed);
              }}
            />
            <label htmlFor="agree">
              <span />
              잘못된 주소를 입력 후 선물 시 NFT를 다시 찾을 수 없으며, 해당 책임은 모두 주소를 직접 입력한 본인에게
              있음을 동의합니다.
            </label>
          </div>

          <a onClick={onHandler} className="btn-line" style={cursorPointerStyle}>
            선물하기
          </a>
          <a onClick={closeModal} className="pop-close" style={cursorPointerStyle}>
            Close
          </a>
        </div>

        {/*<div className="pop-type01 pop-gift-finish">*/}
        {/*  <p>선물하기가 완료되었습니다.</p>*/}
        {/*  <figure><img src="./images/img_shop-gift.jpg" alt="" /></figure>*/}
        {/*  <div className="info">*/}
        {/*    <span>To:</span>*/}
        {/*    <div>1x71C7656EC7ab88b098defB751B7401B5f6d8976F</div>*/}
        {/*    <a href="#" className="btn-transaction">트랜잭션 보기</a>*/}
        {/*  </div>*/}
        {/*  <a href="#" className="pop-close">Close</a>*/}
        {/*</div>*/}
      </div>
    </>
  );
};

export default PresentModal;
