import Slider from 'react-slick';

import illust01 from '../../assets/images/img_illust01.png';
import illust02 from '../../assets/images/img_illust02.png';

import product01 from '../../assets/images/img_product01.png';
import product02 from '../../assets/images/img_product02.png';
import product03 from '../../assets/images/img_product03.png';
import papaground from '../../assets/images/img_papaground.jpg';
import sherpaClub from '../../assets/images/img_sherpa-club.png';
import logo_white from '../../assets/images/logo_white.png';
import img_membership from '../../assets/images/img_membership-m.png';
import styled from 'styled-components';
import right_img from '../../assets/images/bg_arrow-next.png';
import left_img from '../../assets/images/bg_arrow-prev.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isCorrectNetworkAtom, isMatchAccountAtom, pathnameInfoAtom } from '../../store/atom';
import membership_video from 'assets/video/membership.mp4';
import error_img from 'assets/images/ico_error.png';
import img_logo_papa from 'assets/images/img_logo-pprp.png';
export const PContainer = styled.p`
    z-index: 0;
  `
export const AccountErrorText = styled.p`
  z-index: 0;
  position: absolute;
  top: 142px;
  right: 10px;
  background: #bc6867 url(${error_img}) no-repeat;
  border-radius: 8px;
  padding: 0 12px 0 46px;
  font-size: 12px;
  font-weight: 500;
  line-height: 44px;
  color: #fff;
  font-family: 'Spoqa Han Sans Neo';
  background-size: auto 24px;
  background-position: 12px 10px;

  @media (max-width: 1280px) {
     {
      /*top:56px;*/
      right: auto;
      margin: 0 16px;
      padding: 14px 16px 14px 56px;
      font-size: 13px;
      line-height: normal;
      background-position: 16px 14px;
      top: 160px;
    }
  }
`;

const SliderContainer = styled(Slider)`
  margin: 0 auto !important;
  width: 1242px !important;
  .slick-track {
  }

  .slick-slide {
    width: 372px !important;
    margin: 0 21px !important;

    //padding-right: 40px !important;
    //padding-left: 30px !important;
  }

  //.slick-current {
  //  padding-left: 80px !important;
  //}
  .slick-cloned {
    margin-left: 15px !important;
  }

  @media (max-width: 1280px) {
    .slick-slide {
      display: flex;
      justify-content: center;
    }
  }
`;

export const UlContainer = styled.ul`
  overflow: none !important;
`;

const ProductionContainer = styled.div`
  display: block !important;
`;

const HomePage = () => {
  const setPathnameInfo = useSetRecoilState(pathnameInfoAtom);
  const isCorrectNetwork = useRecoilValue(isCorrectNetworkAtom);
  const isMatchAccount = useRecoilValue(isMatchAccountAtom);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <LeftNavButton />,
    nextArrow: <RightNavButton />,
  };

  const NextArrowImage = styled.img`
    position: relative;
    width: 24px;
    bottom: 381px;
    cursor: pointer;
    z-index: 100000;
    right: -1260px;
    cursor: pointer;

    @media (max-width: 1280px) {
      display: none;
    }
  `;

  const PreviousArrowImage = styled.img`
    width: 24px;
    position: relative;
    top: 210px;
    left: -29px;
    cursor: pointer;

    @media (max-width: 1280px) {
      display: none;
    }
  `;



  function LeftNavButton(props) {
    const { className, style, onClick } = props;
    return (
      <div className="slick-arrow" style={{ ...style, display: 'block' }} onClick={onClick}>
        <PreviousArrowImage src={left_img} alt="arrow_left" />
      </div>
    );
  }

  function RightNavButton(props) {
    const { className, style, onClick } = props;
    return (
      <div className="slick-arrow" style={{ ...style, display: 'block' }} onClick={onClick}>
        <NextArrowImage src={right_img} alt="arrow_left" />
      </div>
    );
  }

  const { t } = useTranslation();



  return (
    <>
      <article>
        {isCorrectNetwork && <PContainer className="network-error">{t(`common.change_metamask_network`)}</PContainer>}

        {isMatchAccount && <AccountErrorText>{t(`home.header_account_error`)}</AccountErrorText>}

        <section className="kv">
          <div className="inner-box">
            <div className="text">
              <span>
                {t(`home.main_title_01`)}
                <i className="br">{t(`home.main_title_02`)}</i>
              </span>
              <strong>
                ABOVE <i className="br">GROUND</i> MALL
              </strong>
              <p className="line">{t(`home.main_desc_01`)}</p>
              <p>{t(`home.main_desc_02`)}</p>
            </div>
          </div>
        </section>

        <section className="feature01">
          <div className="inner-box">
            <h2 className="tit">
              {t(`home.main_product_word`)} <span className="eng">NFT</span> {t(`home.main_change_word`)}
            </h2>
            <p className="desc">{t(`home.main_desc_03`)}</p>
            <span className="illust" style={{ zIndex: -1 }}>
              <img src={illust01} alt="" />
            </span>
            <ProductionContainer className="product-wrap slider-w">
              <div className="slider">
                <UlContainer>
                  <SliderContainer {...settings}>
                    <li>
                      <figure>
                        <img src={product01} alt="" />
                      </figure>
                      <div className="info">
                        <em className="tag orange">Legendary</em>
                        <strong>가지 클리어링 필링 패드 토너 70매</strong>
                        <a href="#" className="btn-round">
                          교환하기
                        </a>
                      </div>
                    </li>

                    <li>
                      <figure>
                        <img src={product02} alt="" />
                      </figure>
                      <div className="info">
                        <em className="tag purple">Epic</em>
                        <strong>인스밀 X 파파레서피 제주 보리 핸…</strong>
                        <a href="#" className="btn-round">
                          교환하기
                        </a>
                      </div>
                    </li>

                    <li>
                      <figure>
                        <img src={product03} alt="" />
                      </figure>
                      <div className="info">
                        <em className="tag purple">Epic</em>
                        <strong>티트리 컨트롤 패드 70매</strong>
                        <a href="#" className="btn-round">
                          교환하기
                        </a>
                      </div>
                    </li>

                    <li>
                      <figure>
                        <img src={product01} alt="" />
                      </figure>
                      <div className="info">
                        <em className="tag orange">Legendary</em>
                        <strong>가지 클리어링 필링 패드 토너 70매</strong>
                        <a href="#" className="btn-round">
                          교환하기
                        </a>
                      </div>
                    </li>

                    <li>
                      <figure>
                        <img src={product02} alt="" />
                      </figure>
                      <div className="info">
                        <em className="tag purple">Epic</em>
                        <strong>인스밀 X 파파레서피 제주 보리 핸…</strong>
                        <a href="#" className="btn-round">
                          교환하기
                        </a>
                      </div>
                    </li>

                    <li>
                      <figure>
                        <img src={product03} alt="" />
                      </figure>
                      <div className="info">
                        <em className="tag purple">Epic</em>
                        <strong>티트리 컨트롤 패드 70매</strong>
                        <a href="#" className="btn-round">
                          교환하기
                        </a>
                      </div>
                    </li>
                  </SliderContainer>
                </UlContainer>
              </div>
            </ProductionContainer>
          </div>
        </section>

        <section className="feature02">
          <div className="inner-box">
            <div className="flex">
              <figure>
                <img src={papaground} alt="" />
              </figure>
              <div className="text">
                <h2 className="tit">
                  {t(`home.main_desc_04`)} <i className="br">{t(`home.main_desc_05`)}</i>
                </h2>
                <div className="desc">
                  <p>{t(`home.main_desc_06`)}</p>
                  <p>
                    {t(`home.main_desc_07`)} <br />
                    {t(`home.main_desc_08`)}
                  </p>
                </div>
                <strong>{t(`home.main_desc_09`)}</strong>
                <Link
                  to="/ground"
                  onClick={() => {
                    setPathnameInfo({ text: 'PAPA GROUND', route: '/ground', isHashRoute: false });
                    window.scrollTo(0, 0);
                  }}
                  className="btn-line"
                >
                  What is PAPA GROUND?
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="feature03">
          <div className="inner-box">
            <strong dangerouslySetInnerHTML={{ __html: t(`home.main_desc_26`) }}>
              {/*파파레서피의 <span className="eng">Exclusive Membership,</span> <i className="br">“셰르파 클럽”</i>*/}
            </strong>
            <div className="flex">
              <figure>
                <img src={sherpaClub} alt="" />
              </figure>
              <div className="text">
                <h2 className="tit text-l">
                  {t(`home.main_desc_10`)} <i className="br">{t(`home.main_desc_11`)}</i>
                </h2>
                <p className="desc">
                  {t(`home.main_desc_12`)}
                  <br /> {t(`home.main_desc_13`)}
                </p>
                <p className="desc">{t(`home.main_desc_14`)}</p>
              </div>
            </div>
          </div>
        </section>

        <section className="feature04">
          <h2 className="tit eng white">Membership Benefits</h2>
          <span className="illust" style={{ zIndex: -1 }}>
            <img src={illust02} alt="" />
          </span>
          {/*<div className="membership">*/}
          {/*  <div className="membership01">*/}
          {/*    <strong className="mem-tit">*/}
          {/*      <img src={img_logo_papa} alt="" />*/}
          {/*    </strong>*/}
          {/*    <ul>*/}
          {/*      <li>{t(`home.main_desc_15`)}</li>*/}
          {/*      <li className="bg">{t(`home.main_desc_16`)}</li>*/}
          {/*      <li>{t(`home.main_desc_17`)}</li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*  <div className="membership02">*/}
          {/*    <strong className="mem-tit">Above Ground</strong>*/}
          {/*    <ul>*/}
          {/*      <li>{t(`home.main_desc_18`)}</li>*/}
          {/*      <li className="bg">{t(`home.main_desc_19`)}</li>*/}
          {/*      <li>{t(`home.main_desc_20`)}</li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*  <div className="membership03">*/}
          {/*    <strong className="mem-tit">Papa Ground</strong>*/}
          {/*    <ul>*/}
          {/*      <li>{t(`home.main_desc_21`)}</li>*/}
          {/*      <li className="bg">{t(`home.main_desc_22`)}</li>*/}
          {/*      <li>{t(`home.main_desc_23`)}</li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<span>(혜택은 계속 추가될 예정입니다.)</span>*/}
          <p>
            {/*{t(`home.main_desc_24`)}*/}
            <i className="br">{t(`home.main_desc_25`)}</i>
          </p>
          <figure>
            <img src="https://www.aboveground.town/static/media/mixed.5bd16548.gif" alt="" />
          </figure>
          <Link
            to="/membership"
            onClick={() => {
              setPathnameInfo({ text: 'MEMBERSHIP', route: '/membership', isHashRoute: false });
              window.scrollTo(0, 0);
            }}
            className="btn-line"
            style={{ zIndex: 10 }}
          >
            {t(`home.main_read_more`)}
          </Link>
        </section>
      </article>
    </>
  );
};

export default HomePage;
