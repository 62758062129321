import { Link, useHistory, useLocation } from 'react-router-dom';
import { headerContents, routes } from '../../common/contants';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import close_img from '../../assets/images/btn_menu-close.png';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  accountInfoAtom,
  currentLangAtom,
  isCorrectNetworkAtom,
  isLoginAtom,
  isMatchAccountAtom,
  pathnameInfoAtom,
  rpcInfoAtom,
} from '../../store/atom';
import Web3 from 'web3';
import { isMobile, metamaskFunc } from '../../lib/util';
import { cursorPointerStyle } from '../../styles/index';
import { getMyInfo, signIn, verifyNonce } from 'api/account';
import { APICore, getUserFromSession, setAuthorization } from 'api/index';
import { localeLangsUtil } from '../../lib/locale';
import i18n from 'i18next';
import useModal from '../../hooks/useModal';
import RequireLoginModal from '../Modal/RequireLogin';
import OutsideClickHandler from 'react-outside-click-handler';
import { getRpcData } from '../../api/app';
import { useTranslation } from 'react-i18next';

const api = new APICore();
const web3 = new Web3(window.ethereum);

const MobileNavContainer = styled.div`
  @media (max-width: 1280px) {
    right: ${(props) => (props.click ? '0px' : '-100%')};
    transition: all 0.25s;
  }
`;

const DivWrapper = styled.div`
  position: absolute;
  top: 43px !important;
  left: -12px !important;
`;

const ImgWrapper = styled.img`
  border-radius: 50%;
`;

const Header = () => {
  const { ABOUT } = routes;
  const [isWhiteVerPage, setIsWhiteVerPage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [isHover, setIsHover] = useState(false);
  const pathname = location.pathname.split('/');
  const [currentLanguage, setCurrentLanguage] = useRecoilState(currentLangAtom);
  const [isCorrectNetwork, setIsCorrectNetwork] = useRecoilState(isCorrectNetworkAtom);
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [loading, setLoading] = useState(false);
  const [isProfileHover, setIsProfileHover] = useState(false);
  const [currentTab, setCurrentTab] = useState('HOME');
  const [pathnameInfo, setPathnameInfo] = useRecoilState(pathnameInfoAtom);
  const { ModalPortal, openModal, closeModal } = useModal();
  const rpcInfo = useRecoilValue(rpcInfoAtom);
  const history = useHistory();
  const [isMatchAccount, setIsMatchAccount] = useRecoilState(isMatchAccountAtom);
  const { t } = useTranslation();

  const onMouseLeaveHandler = () => {
    setIsHover(false);
  };

  const onMouseHoverHandler = () => {
    setIsHover(true);
  };

  const onCloseNav = () => {
    const content = document.querySelector('#contents');
    const html = document.querySelector('html');
    content.classList.remove('dim');
    html.classList.remove('nav-open');
    setIsOpen(!isOpen);
  };

  const handleClick = async () => {
    if (!window.ethereum && !isMobile()) {
      alert('메타마스크를 설치해주세요.');
      return;
    }
    const web3 = new Web3(window.ethereum);
    setLoading(true);
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x1' }],
    });

    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });

    // const getChainId = await web3.eth.getChainId();
    const publicAddress = accounts[0]?.toLowerCase();

    const signInData = await signIn(publicAddress);
    const signMessage = signInData.sigmessage.split(':')[0] + ':';

    const signature = await web3.eth.personal.sign(`${signMessage} ${signInData.nonce}`, publicAddress, '');
    const body = { address: publicAddress, signature };
    await verifyNonce(body, signInData.token);
    setAuthorization(signInData.token);
    const myInfoData = await getMyInfo();
    if (JSON.parse(myInfoData.nonceVerified)) {
      api.setLoggedInUser(signInData.token);

      setAccountInfo({
        address: myInfoData.address,
        uid: myInfoData.uid,
        sid: myInfoData.sid,
        nonceVerified: myInfoData.nonceVerified,
        name: myInfoData.name,
        profile: myInfoData.profile,
      });
      setIsLogin(true);
      setLoading(false);
    }

    const chainId = await web3.eth.getChainId();
    setIsCorrectNetwork('0x1' !== `0x${chainId.toString(16)}`);

    setLoading(false);
  };

  const openMenu = () => {
    const content = document.querySelector('#contents');
    const html = document.querySelector('html');

    content.setAttribute('class', 'dim');
    html.setAttribute('class', 'nav-open');
    setIsOpen(!isOpen);
  };

  const onLogOut = async () => {
    api.setLoggedInUser(null);
    setIsLogin(false);
    setAccountInfo({
      address: '',
      uid: '',
      sid: '',
      nonceVerified: '',
      name: '',
    });
    window.location.reload();
  };
  const compareAccount = async () => {
    const myInfo = await getMyInfo();
    if (myInfo) {
      const accounts = await web3.eth.getAccounts();
      setIsMatchAccount(accounts[0]?.toLowerCase() !== myInfo?.address?.toLowerCase());
    }
  };
  const bootstrap = async () => {
    const getToken = getUserFromSession();
    const myInfo = await getMyInfo();

    if (myInfo?.address) {
      const chainId = await web3.eth.getChainId();
      const rpcInfo = await getRpcData();
      setIsCorrectNetwork(`0x${chainId.toString(16)}` !== '0x1');
    }

    if (getToken) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  };

  const changePathnameInfo = () => {
    const findPathName = headerContents.filter((path) => {
      return location.pathname === path.route;
    });

    setPathnameInfo(findPathName[0]);
  };

  useEffect(() => {
    if (!window.ethereum) {
      return;
    }

    if (!window.ethereum && !isMobile()) {
      alert('메타마스크를 설치해주세요.');
      return;
    }

    if (window.ethereum) {
      bootstrap();
      changePathnameInfo();
    }

    // if (!window.chrome) {
    //   alert('크롬에서 실행해주세요.');
    //   return;
    // }

    window.ethereum.on('accountsChanged', async (account) => {
      if (!account.length) {
        await onLogOut();
        return;
      }
      if (account && account.length && accountInfo?.address !== account[0]) {
        const signInData = await signIn(account[0]);
        if (!JSON.parse(signInData.nonceVerified)) {
          try {
            setIsMatchAccount(true);
            const signMessage = signInData.sigmessage.split(':')[0] + ':';
            const signature = await web3.eth.personal.sign(`${signMessage} ${signInData.nonce}`, account[0], '');
            const body = { address: account[0], signature };
            await verifyNonce(body, signInData.token);
            setAuthorization(signInData.token);
            api.setLoggedInUser(signInData.token);

            const myInfoData = await getMyInfo();

            setAccountInfo({
              address: myInfoData.address,
              uid: myInfoData.uid,
              sid: myInfoData.sid,
              nonceVerified: myInfoData.nonceVerified,
              name: myInfoData.name,
              profile: myInfoData.profile,
            });
            setIsMatchAccount(false);
          } catch (err) {
            console.log(err.code);
            if (err.code === 4001) {
              alert('로그아웃 되었습니다.');
              await onLogOut();
              return;
            }
          }

          return;
        }
        setAuthorization(signInData.token);
        api.setLoggedInUser(signInData.token);

        const { address, uid, sid, nonceVerified, name, profile } = signInData;
        setAccountInfo({
          address,
          uid,
          sid,
          nonceVerified,
          name,
          profile,
        });
      }
    });

    window.ethereum.on('chainChanged', async (chainId) => {
      const getRpc = await getRpcData();
      setIsCorrectNetwork(chainId !== '0x1');
    });
  }, []);

  useEffect(() => {
    compareAccount();
    const pathnameArr = location.pathname.split('/')[1];
    if (pathnameArr === 'shop') {
      setIsWhiteVerPage(true);
      return;
    }

    setIsWhiteVerPage(false);
  }, [location]);

  console.log('boot', isCorrectNetwork);

  return (
    <>
      <header className={classNames({ 'white-ver': isWhiteVerPage })}>
        <section
          className="header-inner"
          onMouseLeave={() => {
            setIsProfileHover(false);
          }}
        >
          <h1>
            <Link
              to="/"
              onClick={() => {
                setPathnameInfo({ text: 'Home', route: '/', isHashRoute: false });
              }}
            >
              papa recipe logo
            </Link>
          </h1>
          <div>
            <nav>
              <ul className="gnb">
                {headerContents.map((item, i) => {
                  return (
                    <li
                      key={i}
                      className={classNames({
                        curr: pathnameInfo?.text === item.text,
                      })}
                    >
                      {item.text !== 'SHOP' ? (
                        <Link
                          to={item.route}
                          onClick={() => {
                            const findPathname = headerContents.filter((path) => {
                              return path.route === item.route;
                            });
                            setPathnameInfo(findPathname[0]);
                          }}
                        >
                          {item.text}
                        </Link>
                      ) : (
                        <a
                          style={cursorPointerStyle}
                          onClick={async () => {
                            try {
                              await getMyInfo();
                              history.push('/shop/membership');
                              const findPathname = headerContents.filter((path) => {
                                return path.route === item.route;
                              });
                              setPathnameInfo(findPathname[0]);
                            } catch (err) {
                              openModal();
                            }
                          }}
                        >
                          {item.text}
                        </a>
                      )}
                    </li>
                  );
                })}
              </ul>
            </nav>
            {isLogin ? (
              <div
                className="connected"
                onMouseOver={() => {
                  setIsProfileHover(true);
                }}
              >
                <div className="nick-wrap">
                  <div className="nick-thumb">
                    <ImgWrapper src={accountInfo?.profile} />
                  </div>
                  <div className="nick-txt">
                    <button className={classNames({ down: true, on: isProfileHover })}>
                      {accountInfo?.address.slice(0, 6)}...
                    </button>
                    <span>Wallet Connected</span>
                  </div>
                </div>

                <div className="connect-select select-type02" style={{ display: isProfileHover ? 'block' : 'none' }}>
                  <ul>
                    <li className="ico-connect01" style={cursorPointerStyle}>
                      <a
                        onClick={() => {
                          if (!accountInfo || !accountInfo.address) {
                            openModal();
                            return;
                          }
                          history.push('/shop/membership');
                        }}
                      >
                        Shop
                      </a>
                    </li>
                    <li className="ico-connect03" style={cursorPointerStyle} onClick={onLogOut}>
                      <a>Sign out</a>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <a onClick={handleClick} target="_blank" className="btn-line btn-wallet" style={cursorPointerStyle}>
                Connect Wallet
              </a>
            )}

            {/*{isCorrectNetwork && (*/}
            {/*  <p className="network-error">*/}
            {/*    메타마스크가 이더리움 네트워크(Mainnet)에 연결되어 있지 않습니다. 네트워크를 변경해 주세요.*/}
            {/*  </p>*/}
            {/*)}*/}

            <div className="select-box" onMouseLeave={onMouseLeaveHandler} onMouseOver={onMouseHoverHandler}>
              <button className={classNames('down', { on: isHover })}>{currentLanguage}</button>
              <DivWrapper style={{ display: isHover ? 'block' : 'none' }}>
                <ul
                  onClick={(e) => {
                    setCurrentLanguage(e.target.textContent);
                    const convertLang = localeLangsUtil(e.target.textContent);
                    i18n.changeLanguage(convertLang);
                  }}
                >
                  <li style={{ cursor: 'pointer' }}>
                    <a>KOR</a>
                  </li>
                  <li style={{ cursor: 'pointer' }}>
                    <a>ENG</a>
                  </li>
                </ul>
              </DivWrapper>
            </div>
          </div>
        </section>
      </header>
      <button
        className="btn-menu"
        onClick={() => {
          openMenu();
        }}
      >
        button
      </button>

      <MobileNavContainer
        className="mobile-nav"
        click={isOpen}
        onMouseLeave={() => {
          setIsProfileHover(false);
        }}
      >
        <a
          className="close"
          onClick={() => {
            onCloseNav();
            setIsProfileHover(false);
          }}
        >
          <img src={close_img} alt="" />
        </a>
        <div className="gnb-list">
          <div className="select-box" onMouseLeave={onMouseLeaveHandler} onMouseOver={onMouseHoverHandler}>
            <button className={classNames('down', { on: isHover })}>{currentLanguage}</button>
            <div style={{ display: isHover ? 'block' : 'none' }}>
              <ul
                onClick={(e) => {
                  setCurrentLanguage(e.target.textContent);
                  const convertLang = localeLangsUtil(e.target.textContent);
                  i18n.changeLanguage(convertLang);
                }}
              >
                <li>
                  <a>KOR</a>
                </li>
                <li>
                  <a>ENG</a>
                </li>
              </ul>
            </div>
          </div>

          {isLogin && (
            <div
              className="connected"
              onMouseOver={() => {
                setIsProfileHover(true);
              }}
              onMouseLeave={() => {
                setIsProfileHover(false);
              }}
            >
              <div className="nick-wrap">
                <div className="nick-thumb">
                  <ImgWrapper src={accountInfo?.profile} />
                </div>
                <div className="nick-txt">
                  <button className={classNames({ down: true, on: isProfileHover })}>
                    {accountInfo?.address.slice(0, 7)}...
                  </button>
                  <span>Wallet Connected</span>
                </div>
              </div>
              <div className="connect-select select-type02" style={{ display: isProfileHover ? 'block' : 'none' }}>
                <ul>
                  <li className="ico-connect01">
                    <a
                      onClick={() => {
                        if (!accountInfo || !accountInfo.address) {
                          openModal();
                          return;
                        }
                        onCloseNav();
                        history.push('/shop/membership');
                      }}
                    >
                      Shop
                    </a>
                  </li>
                  <li className="ico-connect03">
                    <a onClick={onLogOut}>Sign out</a>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {!accountInfo?.address && !window.ethereum && isMobile() ? (
            <a
              target="_blank"
              className="btn-line btn-wallet"
              href="https://metamask.app.link/dapp/www.aboveground.town/"
            >
              Connect Wallet
            </a>
          ) : !accountInfo?.address ? (
            <a onClick={handleClick} className="btn-line btn-wallet">
              Connect Wallet
            </a>
          ) : null}
          <ul className="gnb">
            {headerContents.map((item, i) => {
              return (
                <li
                  key={i}
                  className={classNames({
                    curr: pathnameInfo?.text === item.text,
                  })}
                  onClick={() => {
                    onCloseNav();
                    setIsProfileHover(false);
                  }}
                >
                  {item.text !== 'SHOP' ? (
                    <Link
                      to={item.route}
                      onClick={() => {
                        const findPathname = headerContents.filter((path) => {
                          return path.route === item.route;
                        });
                        setPathnameInfo(findPathname[0]);
                      }}
                    >
                      {item.text}
                    </Link>
                  ) : (
                    <a
                      style={cursorPointerStyle}
                      onClick={async () => {
                        try {
                          await getMyInfo();
                          history.push('/shop/membership');
                          const findPathname = headerContents.filter((path) => {
                            return path.route === item.route;
                          });
                          setPathnameInfo(findPathname[0]);
                        } catch (err) {
                          openModal();
                        }
                      }}
                    >
                      {item.text}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </MobileNavContainer>

      <ModalPortal>
        <RequireLoginModal closeModal={closeModal} />
      </ModalPortal>
    </>
  );
};

export default Header;
