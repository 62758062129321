import ico_mem_pr1_img from 'assets/images/ico_mem-pr01.png';
import ico_mem_pr2_img from 'assets/images/ico_mem-pr02.png';
import ico_mem_pr3_img from 'assets/images/ico_mem-pr03.png';
import ico_mem_pr4_img from 'assets/images/ico_mem-pr04.png';
import ico_mem_pr5_img from 'assets/images/ico_mem-pr05.png';
import ico_mem_pr6_img from 'assets/images/ico_mem-pr06.png';

const PapaRecipeTab = () => {
  return (
    <div className="cont on">
      <ul>
        <li>
          <div className="flex start">
            <span className="icon">
              <img src={ico_mem_pr1_img} alt="" />
            </span>
            <div className="text">
              <strong>신제품 베타테스터</strong>
              <em className="num">0/3</em>
            </div>
          </div>
          <a href="#" className="btn-mem type01">
            사용 전
          </a>
        </li>
        <li>
          <div className="flex start">
            <span className="icon">
              <img src={ico_mem_pr2_img} alt="" />
            </span>
            <div className="text">
              <strong>온라인몰 상시 할인</strong>
              <em>~2023. 08. 15</em>
            </div>
          </div>
          <a href="#" className="btn-mem type02">
            적용 중
          </a>
        </li>
        <li>
          <div className="flex start">
            <span className="icon">
              <img src={ico_mem_pr3_img} alt="" />
            </span>
            <div className="text">
              <strong>셰르파 대상 체험 이벤트</strong>
              <em>진행 시 알림</em>
            </div>
          </div>
          <a href="#" className="btn-mem type02">
            알림 신청 중
          </a>
        </li>
        <li>
          <div className="flex start">
            <span className="icon">
              <img src={ico_mem_pr4_img} alt="" />
            </span>
            <div className="text">
              <strong>Early Gift Drop</strong>
              <em className="num">0/3</em>
            </div>
          </div>
          <a href="#" className="btn-mem type01">
            사용 전
          </a>
        </li>
        <li>
          <div className="flex start">
            <span className="icon">
              <img src={ico_mem_pr5_img} alt="" />
            </span>
            <div className="text">
              <strong>1 time kit 무료 구독</strong>
              <em className="num">0/2</em>
            </div>
          </div>
          <a href="#" className="btn-mem type03">
            요청하기
          </a>
        </li>
        <li>
          <div className="flex start">
            <span className="icon">
              <img src={ico_mem_pr6_img} alt="" />
            </span>
            <div className="text">
              <strong>신제품 자동 구독</strong>
              <em>~2023. 08. 15</em>
            </div>
          </div>
          <a href="#" className="btn-mem type02">
            적용 중
          </a>
        </li>
      </ul>
    </div>
  );
};

export default PapaRecipeTab;
