const PaymentInformationBox = () => {
  return (
    <>
      <div className="exchange-box">
        <h3 className="small-tit">결제 정보</h3>
        <div className="cont cont-table">
          <table>
            <colgroup>
              <col width="160px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th>총 상품 가격</th>
                <td>38,000 원</td>
              </tr>
              <tr>
                <th>즉시 할인</th>
                <td className="color-orange">- 38,000 원</td>
              </tr>
              <tr>
                <th>쿠폰 적용</th>
                <td>1매</td>
              </tr>
              <tr>
                <th>배송비</th>
                <td>0원</td>
              </tr>
              <tr>
                <th>총 결제금액</th>
                <td>0원</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PaymentInformationBox;
