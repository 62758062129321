import styled from 'styled-components';
const DivWrapper = styled.div`
  .default-input {
    width: 300px !important;
  }
`;

const ApplicationInformationBox = ({ register, errors }) => {
  return (
    <>
      <DivWrapper className="exchange-box">
        <h3 className="small-tit">신청자 정보</h3>
        <div className="cont cont-table">
          <table>
            <colgroup>
              <col width="160px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th>이름</th>
                <td className="input">
                  <input
                    className="default-input"
                    type="text"
                    {...register('applicantName', {
                      required: '필수정보입니다.',
                      pattern: {
                        value: /^[가-힣a-zA-Z]+$/,
                        message: '이름에 특수문자를 사용할 수 없습니다.',
                      },
                    })}
                  />
                  <p className="notice">{errors?.applicantName?.message}</p>
                </td>
              </tr>
              <tr>
                <th>이메일</th>
                <td className="input">
                  <input
                    className="default-input"
                    type="text"
                    {...register('applicantEmail', {
                      required: '필수정보입니다.',
                      pattern: {
                        value: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
                        message: '이메일 형식이 잘못되었습니다. 다시 입력해주세요.',
                      },
                    })}
                  />
                  <p className="notice">{errors?.applicantEmail?.message}</p>
                </td>
              </tr>
              <tr>
                <th>휴대폰 번호</th>
                <td className="input cert">
                  <span className="phone">
                    <input
                      type="number"
                      {...register('applicantPhoneNumber', {
                        valueAsNumber: true,
                        required: '필수정보입니다.',
                        pattern: {
                          value: /^[0-9]+$/,
                          message: '숫자만 입력하세요.',
                        },
                      })}
                    />
                  </span>
                  <a href="#" className="btn-round small">
                    인증번호 발송
                  </a>
                  <p className="notice">{errors?.applicantPhoneNumber?.message}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DivWrapper>
    </>
  );
};

export default ApplicationInformationBox;
