// images
import ico_discord_w from 'assets/images/ico_discod-w.png';
import ico_twitter_w from 'assets/images/ico_twitter-w.png';
import img_membership_w_kv from 'assets/images/img_membership-kv.png';
import img_sneakpee01 from 'assets/images/img_sneakpeek01.png';
import img_sneakpee02 from 'assets/images/img_sneakpeek02.png';
import img_sneakpee03 from 'assets/images/img_sneakpeek03.png';
import img_logo_pprp from 'assets/images/img_logo-pprp.png';

import img_sneakpee01_m from 'assets/images/img_sneakpee01-m.png';
import img_typelist01 from 'assets/images/img_typelist01.png';
import img_typelist02 from 'assets/images/img_typelist02.png';
import img_typelist03 from 'assets/images/img_typelist03.png';
import img_typelist04 from 'assets/images/img_typelist04.png';
import img_typelist05 from 'assets/images/img_typelist05.png';
import img_typelist06 from 'assets/images/img_typelist06.png';
import img_typelist07 from 'assets/images/img_typelist07.png';
import img_typelist08 from 'assets/images/img_typelist08.png';
import img_illust02_membership from 'assets/images/img_illust02-membership.png';
import img_illust02_membership_m from 'assets/images/img_illust02-membership-m.png';
import mixed_gif from 'assets/images/mixed.gif';

import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { AccountErrorText, PContainer, UlContainer } from '../Home';
import left_img from '../../assets/images/bg_arrow-prev.png';
import right_img from '../../assets/images/bg_arrow-next.png';
import { useTranslation } from 'react-i18next';
import { currentLangAtom, isCorrectNetworkAtom, isMatchAccountAtom } from '../../store/atom';
import { useRecoilValue } from 'recoil';
import { getMembershipNftItems, getPeeksInfo } from '../../api/app';
import MembershipPageNftLists from '../../components/MembershipPageNftLists';
import MembershipPageNftMobileLists from '../../components/MembershipPageNftLists/mobileLists';
import { cursorPointerStyle } from '../../styles';

const NftSlider = styled(Slider)`
  //.slick-list {
  //  margin: 6px !important;
  //}
  //.slick-active {
  //  margin: 10px !important;
  //}
  .slick-dots {
    bottom: -40px !important;
    left: -5px !important;
    > li > button::before {
      font-size: 1px !important;
      width: 1px !important;
    }
  }
  figure {
    border-radius: 24px 24px 0 0;
  }
  .slick-slide {
    width: 311px !important;
    float: left !important;
    list-style: none !important;
    position: relative !important;
    min-height: 736px;
    margin: 0 20px 0;
    //overflow: hidden;
    display: inline-block;
    vertical-align: top;
    border-radius: 24px;
    box-shadow: 0 20px 50px 0rgba (71, 77, 70, 0.05);
    -webkit-box-shadow: 0 20px 50px 0 rgb(71 77 70 / 5%);
    -ms-box-shadow: 0 20px 50px 0 rgba(71, 77, 70, 0.05);
    border: solid 1px #e0e4e0;
    background: #fbfbfb;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
`;

const SliderContainer = styled(Slider)`
  margin: 0 auto !important;
  width: 1242px !important;
  .slick-track {
  }

  //.img-container {
  //  width: 372px !important;
  //}

  .slick-slide {
    width: 372px !important;
    margin: 0 21px !important;

    //padding-right: 40px !important;
    //padding-left: 30px !important;
  }

  //.slick-current {
  //  padding-left: 80px !important;
  //}
  .slick-cloned {
    margin-left: 15px !important;
  }

  @media (max-width: 1280px) {
    width: 1150px !important;
    .slick-list {
      //margin: 0 -15px;
      padding: 0 50px;
    }
    .slick-slide {
      //display: flex;
      //justify-content: center;
      width: 260px !important;
      height: 260px !important;
      margin: 0 15px !important;
      li {
        margin: 0 !important;
      }
    }
  }
`;

const PreviousArrowImage = styled.img`
  width: 24px;
  position: relative;
  top: 210px;
  left: -29px;
  cursor: pointer;

  @media (max-width: 1280px) {
    display: none;
  }
`;
const NextArrowImage = styled.img`
  position: relative;
  width: 24px;
  bottom: 190px;
  cursor: pointer;
  z-index: 100000;
  right: -1255px;
  cursor: pointer;

  @media (max-width: 1280px) {
    display: none;
  }
`;

const DivWrapper = styled.div`
  margin-top: 100px;
  background: #fff;
  padding: 50px;
  section {
    padding: 0px !important;
  }

  @media (max-width: 1280px) {
    section {
      padding-top: 0px !important;
    }
  }
`;

const snakeDummyData = [img_sneakpee01, img_sneakpee02, img_sneakpee03, img_sneakpee01, img_sneakpee02, img_sneakpee03];
function LeftNavButton(props) {
  const { className, style, onClick } = props;
  return (
    <div className="slick-arrow" style={{ ...style, display: 'block' }} onClick={onClick}>
      <PreviousArrowImage src={left_img} alt="arrow_left" />
    </div>
  );
}

function RightNavButton(props) {
  const { className, style, onClick } = props;
  return (
    <div className="slick-arrow" style={{ ...style, display: 'block' }} onClick={onClick}>
      <NextArrowImage src={right_img} alt="arrow_left" />
    </div>
  );
}
const MemberShipPage = () => {
  const [qnaSelect, setQnaSelect] = useState('');
  const [peekImageArr, setPeekImageArr] = useState([]);
  const [membershipNfts, setMembershipNfts] = useState([]);
  const currentLang = useRecoilValue(currentLangAtom);
  const isCorrectNetwork = useRecoilValue(isCorrectNetworkAtom);
  const isMatchAccount = useRecoilValue(isMatchAccountAtom);

  const membershipNftSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
          arrows: false,
          initialSlide: 2,
        },
      },
    ],
  };

  const settings = {
    dots: false,
    // infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: <LeftNavButton />,
    nextArrow: <RightNavButton />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // initialSlide: 3,
          arrows: false,
        },
      },
    ],
  };

  const { t } = useTranslation();

  const faqLists = [
    { id: '01', question: t(`membership.main_desc_47`), answer: <p>{t(`membership.main_desc_51`)}</p> },
    { id: '02', question: t(`membership.main_desc_48`), answer: <p>{t(`membership.main_desc_52`)}</p> },
    {
      id: '03',
      question: t(`membership.main_desc_49`),
      answer: <p>{t(`membership.main_desc_53`)}</p>,
    },
    {
      id: '04',
      question: t(`membership.main_desc_50`),
      answer: <p>{t(`membership.main_desc_54`)}</p>,
    },
  ];

  const bootStrap = async () => {
    await getPeeksInfo().then(setPeekImageArr);
    await getMembershipNftItems().then(setMembershipNfts);
  };

  useEffect(() => {
    bootStrap();
  }, []);

  return (
    <>
      <article>
        {isCorrectNetwork && <PContainer className="network-error">{t(`common.change_metamask_network`)} </PContainer>}
        {isMatchAccount && <AccountErrorText>{t(`home.header_account_error`)}</AccountErrorText>}

        <section className="kv">
          <div className="inner-box">
            <div className="text">
              {currentLang === 'KOR' ? (
                <span>
                  파파레서피의 <em className="eng br">Exclusive Membership</em>
                </span>
              ) : (
                <span>
                  Exclusive Membership <em className="eng br">of Papa Recipe</em>
                </span>
              )}
              <strong>SHERPA CLUB</strong>
              <p className="line">
                {t(`membership.main_desc_01`)} <br />
                {t(`membership.main_desc_02`)}
              </p>
              <p>{t(`membership.main_desc_03`)}</p>
              <div className="count-box">
                <em>00</em>days, <em>00:00:00</em>
              </div>
              <span className="btn-box">
                <a href="#">
                  <img src={ico_discord_w} alt="" />
                </a>
                <a href="#">
                  <img src={ico_twitter_w} alt="" />
                </a>
              </span>
            </div>
            <figure>
              <img src={mixed_gif} alt="" />
            </figure>
          </div>
        </section>

        <section className="membership01">
          <div className="inner-box">
            <h2 className="tit white eng">Story</h2>
            <div className="desc">
              <p>
                {t(`membership.main_desc_04`)}
                <br />
                {t(`membership.main_desc_05`)}
                <br />
                {t(`membership.main_desc_06`)}
              </p>
              <p>
                {t(`membership.main_desc_07`)}
                <br />
                {t(`membership.main_desc_08`)}
                <br />
                {t(`membership.main_desc_09`)}
                <br />
                {t(`membership.main_desc_10`)}
              </p>
              <p>
                {t(`membership.main_desc_11`)}
                <br />
                {t(`membership.main_desc_12`)}
                <br />
                {t(`membership.main_desc_13`)}
              </p>
            </div>
          </div>
        </section>

        <section className="membership02">
          <div className="inner-box">
            <h2 className="tit white eng">Sneak Peeks</h2>
            <div className="slide-wrap slide-w">
              <div className="slider">
                <UlContainer>
                  <SliderContainer {...settings}>
                    {peekImageArr.map((item, i) => (
                      <li key={i.toString() + item} className="img-container">
                        <figure>
                          <img src={item} alt="" />
                        </figure>
                      </li>
                    ))}
                  </SliderContainer>
                </UlContainer>
              </div>
            </div>

            <div className="slide-wrap slide-m">
              <div className="slider">
                <UlContainer>
                  <SliderContainer {...settings}>
                    {peekImageArr.map((item, i) => (
                      <li key={i.toString() + item} style={{ width: '260px', margin: '0px' }}>
                        <figure>
                          <img src={item} alt="" />
                        </figure>
                      </li>
                    ))}
                  </SliderContainer>
                </UlContainer>
              </div>
            </div>
          </div>
        </section>

        <section className="membership03">
          <h2 className="tit">
            {t(`membership.main_desc_14`)} <i className="br">{t(`membership.main_desc_15`)}</i>
          </h2>
          <strong>Membership Type</strong>
          <div className="desc">
            <p>
              {t(`membership.main_desc_16`)}
              <br />
              {t(`membership.main_desc_17`)}
              <br />
              {t(`membership.main_desc_18`)}
              <br />
              {t(`membership.main_desc_19`)})
            </p>
          </div>
          <ul className="type-list pc">
            {membershipNfts.map((item, i) => (
              <MembershipPageNftLists key={i} {...item} />
            ))}
          </ul>
          <div className="slide-wrap" style={{ paddingLeft: '22px' }}>
            <ul className="type-list mobile">
              <NftSlider {...membershipNftSliderSettings}>
                {membershipNfts.length > 0 &&
                  membershipNfts.map((item, i) => <MembershipPageNftMobileLists key={i} {...item} />)}
              </NftSlider>
            </ul>
          </div>
        </section>

        <section className="membership04">
          <h2 className="tit eng">Holder Benefits</h2>
          <span className="illust pc">
            <img src={img_illust02_membership} alt="" />
          </span>
          <span className="illust mobile">
            <img src={img_illust02_membership_m} alt="" />
          </span>
          <div className="desc">
            <p>
              {t(`membership.main_desc_20`)}
              <br className="mobile" /> {t(`membership.main_desc_21`)}
              <br className="mobile" />
              <br />
              {t(`membership.main_desc_22`)}
              <br className="mobile" />
              {t(`membership.main_desc_23`)}
              <br className="mobile" /> {t(`membership.main_desc_24`)}
              <br className="mobile" /> {t(`membership.main_desc_44`)}
              <p>
                {t(`membership.main_desc_45`)}
                <br className="mobile" /> {t(`membership.main_desc_46`)}
                <br />
                <br className="mobile" />
                <strong>{t(`membership.main_desc_25`)}</strong>
              </p>
            </p>
          </div>
          <DivWrapper>
            <section className="ground04">
              <strong>{t(`membership.main_desc_55`)}</strong>
              <a style={cursorPointerStyle} className="btn-line green">
                {t(`membership.more_btn`)}
              </a>
            </section>
          </DivWrapper>

          {/*<div className="benefit">*/}
          {/*  <div className="benefit01">*/}
          {/*    <strong className="mem-tit">*/}
          {/*      <img src={img_logo_pprp} alt="" />*/}
          {/*    </strong>*/}
          {/*    <ul>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_26`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_27`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_28`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_29`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_30`)}</span>*/}
          {/*      </li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*  <div className="benefit02">*/}
          {/*    <strong className="mem-tit">Above Ground</strong>*/}
          {/*    <ul>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_31`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_32`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_33`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_34`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_35`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_36`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_37`)}</span>*/}
          {/*      </li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*  <div className="benefit03">*/}
          {/*    <strong className="mem-tit">Papa Ground</strong>*/}

          {/*    <ul>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_38`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_39`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_40`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_41`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_42`)}</span>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span>{t(`membership.main_desc_43`)}</span>*/}
          {/*      </li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </section>

        <section className="membership06">
          <h2 className="tit">FAQ</h2>
          <ul className="qna-list">
            {faqLists.map((list, i) => (
              <li
                key={i}
                style={{ transition: 'height 2s', cursor: 'pointer' }}
                className={qnaSelect === list.id ? 'on' : ''}
                onClick={(e) => {
                  const liText = e.currentTarget.textContent;
                  setQnaSelect(liText.slice(0, 2));
                  if (qnaSelect && qnaSelect === list.id) {
                    setQnaSelect('');
                  }
                }}
              >
                <div className="question">
                  <a>
                    <em>{list.id}</em>
                    {list.question}
                  </a>
                </div>
                <SlideDown className="my-dropdown-slidedown">
                  <div className="answer">{list.answer}</div>
                </SlideDown>
              </li>
            ))}
          </ul>
        </section>
      </article>
    </>
  );
};

export default MemberShipPage;
