import ico_mem_pg1_img from 'assets/images/ico_mem-pg01.png';
import ico_mem_pg2_img from 'assets/images/ico_mem-pg02.png';
import ico_mem_pg3_img from 'assets/images/ico_mem-pg03.png';
import ico_mem_pg4_img from 'assets/images/ico_mem-pg04.png';
import ico_mem_pg5_img from 'assets/images/ico_mem-pg05.png';
import ico_mem_pg6_img from 'assets/images/ico_mem-pg06.png';

const GroundTab = () => {
  return (
    <>
      <div className="cont on">
        {/*<p className="desc">혜택을 사용하려면 게임에 연동되어 있어야 합니다.</p>*/}
        {/*<div className="btn">*/}
        {/*  <a className="btn-small-round">*/}
        {/*    Papa Ground 와 연동*/}
        {/*  </a>*/}
        {/*  <a className="btn-small-round disabled">*/}
        {/*    Connected*/}
        {/*  </a>*/}
        {/*</div>*/}
        <ul>
          <li>
            <div className="flex start">
              <span className="icon">
                <img src={ico_mem_pg1_img} alt="" />
              </span>
              <div className="text">
                <strong>경험치 추가 획득</strong>
                <em className="num add">60%</em>
              </div>
            </div>
            <a href="#" className="btn-mem type02">
              적용 중
            </a>
          </li>
          <li>
            <div className="flex start">
              <span className="icon">
                <img src={ico_mem_pg2_img} alt="" />
              </span>
              <div className="text">
                <strong>상품NFT 획득 확률</strong>
                <em className="num add">30%</em>
              </div>
            </div>
            <a href="#" className="btn-mem type02">
              적용 중
            </a>
          </li>
          <li>
            <div className="flex start">
              <span className="icon">
                <img src={ico_mem_pg3_img} alt="" />
              </span>
              <div className="text">
                <strong>원료 및 재료 채집 속도</strong>
                <em className="num up">30%</em>
              </div>
            </div>
            <a href="#" className="btn-mem type02">
              적용 중
            </a>
          </li>
          <li>
            <div className="flex start">
              <span className="icon">
                <img src={ico_mem_pg4_img} alt="" />
              </span>
              <div className="text">
                <strong>상품 NFT 제작 속도</strong>
                <em className="num up">60%</em>
              </div>
            </div>
            <a href="#" className="btn-mem type02">
              적용 중
            </a>
          </li>
          <li>
            <div className="flex start">
              <span className="icon">
                <img src={ico_mem_pg5_img} alt="" />
              </span>
              <div className="text">
                <strong>랜덤 이벤트 당첨 확률</strong>
                <em className="num add">30%</em>
              </div>
            </div>
            <a href="#" className="btn-mem type02">
              적용 중
            </a>
          </li>
          <li>
            <div className="flex start up">
              <span className="icon">
                <img src={ico_mem_pg6_img} alt="" />
              </span>
              <div className="text">
                <strong>Limited Edition 아바타</strong>
              </div>
            </div>
            <a href="#" className="btn-mem type03">
              요청하기
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default GroundTab;
