export const routes = {
  HOME: '/',
  ABOUT: '/about',
  GROUND: '/ground',
  MEMBERSHIP: '/membership',
  SHOP: '/shop',
  ORDER_CHECKOUT: '/shop/order/:productId/checkout',
};

export const headerContents = [
  { text: 'Home', route: '/', isHashRoute: false },
  { text: 'MEMBERSHIP', route: '/membership', isHashRoute: false },
  { text: 'PAPA GROUND', route: '/ground', isHashRoute: false },
  { text: 'ABOUT', route: '/about', isHashRoute: false },
  { text: 'SHOP', route: '/shop/membership', isHashRoute: false },
];

export const DDAY = '2022-04-30 17:00:00';

export const polygonNetworkChainId = [137, 80001];
