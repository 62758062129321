import ico_mem_ag1_img from 'assets/images/ico_mem-ag01.png';
import ico_mem_ag2_img from 'assets/images/ico_mem-ag02.png';
import ico_mem_ag3_img from 'assets/images/ico_mem-ag03.png';
import ico_mem_ag4_img from 'assets/images/ico_mem-ag04.png';
import ico_mem_ag5_img from 'assets/images/ico_mem-ag05.png';
import ico_mem_ag6_img from 'assets/images/ico_mem-ag06.png';

const AboveTab = () => {
  return (
    <>
      <div className="cont on">
        <ul>
          <li>
            <div className="flex start">
              <span className="icon">
                <img src={ico_mem_ag1_img} alt="" />
              </span>
              <div className="text">
                <strong>리조트A 무료숙박권</strong>
                <em className="num">0/9</em>
              </div>
            </div>
            <a href="#" className="btn-mem type03">
              발급 하기
            </a>
          </li>
          <li>
            <div className="flex start">
              <span className="icon">
                <img src={ico_mem_ag2_img} alt="" />
              </span>
              <div className="text">
                <strong>리조트B 무료숙박권</strong>
                <em className="num">40%</em>
              </div>
            </div>
            <a href="#" className="btn-mem type03">
              발급 하기
            </a>
          </li>
          <li>
            <div className="flex start">
              <span className="icon">
                <img src={ico_mem_ag3_img} alt="" />
              </span>
              <div className="text">
                <strong>리조트C 무료숙박권</strong>
                <em className="num">50%</em>
              </div>
            </div>
            <a href="#" className="btn-mem type03">
              발급 하기
            </a>
          </li>
          <li>
            <div className="flex start">
              <span className="icon">
                <img src={ico_mem_ag4_img} alt="" />
              </span>
              <div className="text">
                <strong>전시회 무료 관람권</strong>
                <em className="num">0/9</em>
              </div>
            </div>
            <a href="#" className="btn-mem type03">
              발급 하기
            </a>
          </li>
          <li>
            <div className="flex start">
              <span className="icon">
                <img src={ico_mem_ag5_img} alt="" />
              </span>
              <div className="text">
                <strong>ABG 체험행사 초대권</strong>
                <em className="num">0/12</em>
              </div>
            </div>
            <a href="#" className="btn-mem type03">
              요청하기
            </a>
          </li>
          <li>
            <div className="flex start">
              <span className="icon">
                <img src={ico_mem_ag6_img} alt="" />
              </span>
              <div className="text">
                <strong>한정판 Goods</strong>
                <em className="num">0/3</em>
              </div>
            </div>
            <a href="#" className="btn-mem type03">
              요청하기
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AboveTab;
