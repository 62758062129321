import dummy_img_01 from 'assets/images/img_shop-type01.png';
import dummy_img_02 from 'assets/images/img_shop-type02.png';
import dummy_img_03 from 'assets/images/img_shop-type03.png';
import dummy_img_04 from 'assets/images/img_shop-type04.png';
import dummy_img_05 from 'assets/images/img_shop-type05.png';
import dummy_img_06 from 'assets/images/img_shop-type06.png';
import dummy_img_07 from 'assets/images/img_shop-type07.png';
import { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MemberShipNftLists = ({ id, imgUrl, type, name, tagBgColor, tagTextColor, membershipName }) => {
  const { t } = useTranslation();
  return (
    <>
      <li>
        <figure>
          <img src={imgUrl} alt="" />
          {/*<span className={classNames('tag', tagBgColor, { 'color-b': tagTextColor })}>{type}</span>*/}
        </figure>
        <div className="text">
          <strong className="list-tit">
            {name} <i className="br">{membershipName}</i>
          </strong>
          <Link to={`/shop/membership/${id}/papa-recipe`} className="btn-detail">
            {t(`common.detail`)}
          </Link>
        </div>
      </li>
    </>
  );
};

export default MemberShipNftLists;
