import historyImg from 'assets/images/img_shop-history-product.jpg';

const History = () => {
  return (
    <>
      <div className="cont-box empty">
        <div className="cont on">
          <strong className="ico-empty">거래 내역이 없습니다.</strong>
          <p>게임 또는 이벤트를 통해 각종 제품 NFT를 획득하세요!</p>
        </div>
      </div>
      {/*<div className="cont on">*/}
      {/*  <div className="history-table">*/}
      {/*    <table>*/}
      {/*      <colgroup>*/}
      {/*        <col width="13%" />*/}
      {/*        <col width="16%" />*/}
      {/*        <col width="25%" />*/}
      {/*        <col width="*" />*/}
      {/*        <col width="*" />*/}
      {/*        <col width="*" />*/}
      {/*        <col width="*" />*/}
      {/*        <col width="10%" />*/}
      {/*      </colgroup>*/}

      {/*      <thead>*/}
      {/*        <tr>*/}
      {/*          <th className="empty" />*/}
      {/*          <th>Date</th>*/}
      {/*          <th>Item</th>*/}
      {/*          <th>Rarity</th>*/}
      {/*          <th>Claim</th>*/}
      {/*          <th>From</th>*/}
      {/*          <th>To</th>*/}
      {/*          <th>TXID/배송조회</th>*/}
      {/*        </tr>*/}
      {/*      </thead>*/}

      {/*      <tbody>*/}
      {/*        <tr>*/}
      {/*          <th>Mint</th>*/}
      {/*          <td>08/01/2022 22:15:11</td>*/}
      {/*          <td className="item">*/}
      {/*            <span>*/}
      {/*              <img src={historyImg} alt="" />*/}
      {/*            </span>*/}
      {/*            <p className="ko">가지 클리어링 필링 패드 토너</p>*/}
      {/*          </td>*/}
      {/*          <td>*/}
      {/*            <span className="tag orange">Legendary</span>*/}
      {/*          </td>*/}
      {/*          <td>0/5</td>*/}
      {/*          <td>PAPA GROUND</td>*/}
      {/*          <td>You</td>*/}
      {/*          <td>*/}
      {/*            <span className="tag green">TXID</span>*/}
      {/*          </td>*/}
      {/*        </tr>*/}
      {/*      </tbody>*/}
      {/*    </table>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};

export default History;
