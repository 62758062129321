import { useState } from 'react';
import dummy_img_01 from '../../../assets/images/img_shop-product01.png';
import dummy_img_02 from '../../../assets/images/img_shop-product02.png';
import dummy_img_03 from '../../../assets/images/img_shop-product03.png';
import ProductNftLists from '../../ProductNftLists';
import useModal from '../../../hooks/useModal';
import SuccessPresentModal from '../../Modal/SuccessPresent';
import PresentModal from '../../Modal/Present';

const ProductNFT = () => {
  const [productNftLists, setProductLists] = useState([
    {
      id: 1,
      imgUrl: dummy_img_01,
      tagBgColor: 'orange',
      name: '가지 클리어링 필링 패드 토너 70매',
      type: 'Legendary',
      currentCount: '0',
      totalCount: '5',
    },
    {
      id: 2,
      imgUrl: dummy_img_02,
      tagBgColor: 'purple',
      name: '가지 클리어링 필링 패드 토너 70매',
      type: 'Epic',
      currentCount: '0',
      totalCount: '5',
    },
    {
      id: 3,
      imgUrl: dummy_img_03,
      tagBgColor: 'blue',
      name: '가지 클리어링 필링 패드 토너 70매',
      type: 'Rare',
      currentCount: '0',
      totalCount: '5',
    },
    {
      id: 4,
      imgUrl: dummy_img_01,
      tagBgColor: 'orange',
      name: '가지 클리어링 필링 패드 토너 70매',
      type: 'Legendary',
      currentCount: '0',
      totalCount: '5',
    },
    {
      id: 5,
      imgUrl: dummy_img_02,
      tagBgColor: 'purple',
      name: '가지 클리어링 필링 패드 토너 70매',
      type: 'Epic',
      currentCount: '0',
      totalCount: '5',
    },
    {
      id: 6,
      imgUrl: dummy_img_03,
      tagBgColor: 'blue',
      name: '가지 클리어링 필링 패드 토너 70매',
      type: 'Rare',
      currentCount: '0',
      totalCount: '5',
    },
  ]);

  const { ModalPortal, openModal, closeModal } = useModal();
  const { ModalPortal: SuccessModalPortal, openModal: successOpenModal, closeModal: successCloseModal } = useModal();
  const onPresentHandler = () => {
    closeModal();
    successOpenModal();
  };
  return (
    <>
      {/*<div className="cont on">*/}
      {/*  <ul className="nft-list">*/}
      {/*    {productNftLists.map((item, i) => (*/}
      {/*      <ProductNftLists*/}
      {/*        {...item}*/}
      {/*        key={i}*/}
      {/*        openModal={openModal}*/}
      {/*        successCloseModal={successCloseModal}*/}
      {/*        successOpenModal={successOpenModal}*/}
      {/*        closeModal={closeModal}*/}
      {/*      />*/}
      {/*    ))}*/}
      {/*  </ul>*/}
      {/*  <SuccessModalPortal>*/}
      {/*    <SuccessPresentModal closeModal={successCloseModal} />*/}
      {/*  </SuccessModalPortal>*/}

      {/*</div>*/}

      <div className="cont-box empty">
        <div className="cont on">
          <strong className="ico-empty">NFT를 보유하고 있지 않습니다.</strong>
          <p>게임에 접속하여 각종 제품 NFT를 획득하세요!</p>
          <div className="btn-box">
            <a href="#" className="btn-line green">
              NFT 얻으러 가기
            </a>
          </div>
        </div>
      </div>

      <ModalPortal>
        <PresentModal onHandler={onPresentHandler} closeModal={closeModal} />
      </ModalPortal>
    </>
  );
};

export default ProductNFT;
