export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function metamaskFunc() {
  if (isMobile()) {
    alert('PC에서 접속해주세요.');
    return;
  }

  if (!window.chrome) {
    alert('크롬 브라우저를 이용해주세요.');
    return;
  }

  if (!window.ethereum) {
    alert('메타마스크를 설치헤주세요.');
    return;
  }
}
