const CompleteModal = ({ closeModal }) => {
  return (
    <>
      <div className="popup-wrap" style={{ display: 'block' }}>
        <div className="pop-type01 pop-notice-finish">
          <p className="ico-checkmark">
            정상적으로 등록되었습니다.
            <br />
            오픈 시 등록하신 이메일로 알림을 보내 드립니다.
          </p>
          <a onClick={closeModal} className="btn-round pop-close">
            닫기
          </a>
        </div>
      </div>
    </>
  );
};

export default CompleteModal;
