import { atom } from 'recoil';

export const currentLangAtom = atom({
  key: 'currentLanguage',
  default: 'KOR',
});

export const subClassName = atom({
  key: 'subClassName',
  default: false,
});

export const isMetamaskLogin = atom({
  key: 'isMetamaskLogin',
  default: false,
});

export const accountInfoAtom = atom({
  key: 'accountInfo',
  default: {
    address: '',
    uid: '',
    sid: '',
    nonceVerified: '',
    name: '',
    profile: '',
  },
});

export const isPossibleMint = atom({
  key: 'isPossibleMintAtom',
  default: true,
});

export const contractInfo = atom({
  key: 'contractInfo',
  default: {
    contractAddress: '',
  },
});

export const typyContractInfo = atom({
  key: 'typyContractInfo',
  default: undefined,
  dangerouslyAllowMutability: true,
});

export const minterContractInfoAtom = atom({
  key: 'minterContractInfo',
  default: null,
  dangerouslyAllowMutability: true,
});

export const isPolygonNetwork = atom({
  key: 'isPolygonNetwork',
  default: false,
});

export const mintPageSelectTab = atom({
  key: 'mintPageSelectTab',
  default: 'typy',
});

export const fontContractAddressInfo = atom({
  key: 'fontContractAddressInfo',
  default: '',
});

export const rpcInfoAtom = atom({
  key: 'rpcInfo',
  default: null,
});

export const isCorrectNetworkAtom = atom({
  key: 'isCorrectNetworkAtom',
  default: false,
});

export const isLoginAtom = atom({
  key: 'isLogin',
  default: false,
});

export const pathnameInfoAtom = atom({
  key: 'pathnameInfo',
  default: null,
});

export const isMatchAccountAtom = atom({
  key: 'isMatchAccount',
  default: false,
});
