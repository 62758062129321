import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import './Slick.css';
import './slick-theme.css';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DDAY, routes } from './common/contants';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { accountInfoAtom, isPossibleMint, minterContractInfoAtom, rpcInfoAtom, subClassName } from './store/atom';
import { useHistory, useLocation } from 'react-router-dom';
import { getNetworkRpc, getRpcData } from './api/app';
import Web3 from 'web3';
import momentTimeZone from 'moment-timezone';
import moment from 'moment/moment';
import Header from './components/Header';
import HomePage from './pages/Home';
import Footer from './components/Footer';
import { PublicRouter } from './common/routes';
import classNames from 'classnames';
import { getMyInfo } from 'api/account';
import { setAuthorization } from 'api/index';
import { minterAbi } from './lib/web3Config';
const web3 = new Web3(window.ethereum);

function App() {
  const { ABOUT } = routes;
  const history = useHistory();
  const [isSubPage, setIsSubPage] = useState(false);
  const [isWhiteVerPage, setIsWhiteVerPage] = useState(false);
  const [isShopPage, setIsShopPage] = useState(false);
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [isLogin, setIsLogin] = useState(false);
  const location = useLocation();
  const removeFooterPath = ['/about'];
  const pathnameArr = location.pathname.split('/')[1];
  const isRemoveFooter =
    removeFooterPath.includes(location.pathname) || pathnameArr === 'shop' || pathnameArr === 'ground';
  const [minterContractInfo, setMinterContractInfo] = useRecoilState(minterContractInfoAtom);
  const [rpcInfo, setRpcInfo] = useRecoilState(rpcInfoAtom);
  const bootstrap = async () => {
    try {
      const myInfo = await getMyInfo();

      setAccountInfo({
        address: myInfo.address,
        uid: myInfo.uid,
        sid: myInfo.sid,
        nonceVerified: myInfo.nonceVerified,
        name: myInfo.name,
        profile: myInfo.profile,
      });
      setIsLogin(true);
    } catch (err) {
      setAuthorization('');
      setIsLogin(false);
      setAccountInfo({
        address: '',
        uid: '',
        sid: '',
        nonceVerified: '',
        name: '',
        profile: '',
      });
    }
  };
  const loadRpcData = async () => {
    if (window.ethereum) {
      const rpcData = await getRpcData();
      setRpcInfo({
        chainId: rpcData.chainId,
        address: rpcData.contract,
      });
      const minterContract = new web3.eth.Contract(minterAbi, rpcData.contract);
      setMinterContractInfo(minterContract);
      console.log(minterContract, 'rpcData');
    }
  };

  useEffect(() => {
    const pathnameArr = location.pathname.split('/')[1];

    // if (pathnameArr === 'shop') {
    //   console.log(pathnameArr, 'pathnameArr');
    //   setIsWhiteVerPage(true);
    // } else {
    //   setIsWhiteVerPage(false);
    // }

    if (location.pathname === ABOUT || location.pathname === '/ground' || pathnameArr === 'shop') {
      setIsSubPage(true);
    } else {
      setIsSubPage(false);
    }

    if (pathnameArr === 'shop') {
      setIsShopPage(true);
    } else {
      setIsShopPage(false);
    }
  }, [location]);

  useEffect(() => {
    bootstrap();
    loadRpcData();
  }, []);

  return (
    <>
      <main>
        <div
          id="contents"
          className={classNames({
            'sub-page': isSubPage,
            'white-ver': isWhiteVerPage,
            'papa-membership': location.pathname === '/membership',
            'papa-shop': isShopPage,
          })}
        >
          <Header />
          <PublicRouter />
          {!isRemoveFooter && <Footer />}
        </div>
        <div id="modal" />
      </main>
      {/*<main style={{ height: '100%' }}>*/}
      {/*  <div id="contents" className={subClassNameAtom || isMintPage || isCategoryPage ? 'sub-page' : ''}>*/}
      {/*    {isHomePage ? <HomeHeader /> : <Header />}*/}
      {/*    <PublicRouter />*/}
      {/*  </div>*/}
      {/*  {isMintPage && <Footer />}*/}
      {/*</main>*/}
      {/*<div id="modal" />*/}
      {/*<ReactCountDown date={moment(localTime).valueOf() - differ} renderer={renderer}>*/}
      {/*  <div />*/}
      {/*</ReactCountDown>*/}
    </>
  );
}

export default App;
