import img_product03 from 'assets/images/img_product03.png';
import ApplicationInformationBox from '../../components/Box/ApplicantInformation';
import ShippingInformationBox from '../../components/Box/ShippingInformation';
import PaymentInformationBox from '../../components/Box/PaymentInformation';
import { useForm } from 'react-hook-form';
import useModal from '../../hooks/useModal';
import AddressModal from '../../components/Modal/AddressModal';
import useInput from '../../hooks/useInput';
import { getMyInfo } from '../../api/account';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isCorrectNetworkAtom, isMatchAccountAtom, pathnameInfoAtom } from '../../store/atom';
import RequireLoginModal from '../../components/Modal/RequireLogin';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import { AccountErrorText, PContainer } from '../Home';
import { useTranslation } from 'react-i18next';

const SubmitBtn = styled.button`
  opacity: ${(props) => (props.test ? 0.3 : 1)} !important;
`;

const OrderCheckoutPage = () => {
  const { ModalPortal, openModal, closeModal } = useModal();
  const { ModalPortal: InfoModalPortal, openModal: infoOpenModal, closeModal: infoCloseModal } = useModal();
  const [count, setCount] = useState(1);
  const [address, onChangeAddress, setAddress] = useInput('');
  const [pathnameInfo, setPathnameInfo] = useRecoilState(pathnameInfoAtom);
  const isCorrectNetwork = useRecoilValue(isCorrectNetworkAtom);
  const isMatchAccount = useRecoilValue(isMatchAccountAtom);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, dirtyFields },
    setError,
    clearErrors,
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      applicantEmail: '',
      applicantName: '',
      applicantPhoneNumber: '',
      detailedAddress: '',
      shippingName: '',
      shippingPhoneNumber: '',
      shippingRequest: '',
    },
  });

  const isObjectValueTrue = (obj) => {
    const objLength = Object.keys(obj).length;
    console.log(objLength);
    return objLength === 7;
  };

  const onSubmit = async (data) => {
    console.log(data);
  };
  const bootstrap = async () => {
    try {
      await getMyInfo();
    } catch (err) {
      infoOpenModal();
    }
  };

  const onIncreaseCount = () => {
    setCount((prevState) => prevState + 1);
  };

  const onDecreaseCount = () => {
    setCount((prevState) => {
      console.log(prevState, 'prevState');
      if (prevState === 1) {
        return prevState;
      }

      return prevState - 1;
    });
  };

  const copyValue = () => {
    const getData = getValues(['applicantName', 'applicantPhoneNumber']);
    setValue('shippingName', getData[0], { shouldDirty: true });
    setValue('shippingPhoneNumber', getData[1], { shouldDirty: true });
  };

  useEffect(() => {
    bootstrap();
    setPathnameInfo({ text: 'SHOP', route: '/shop/membership', isHashRoute: false });
  }, []);

  console.log(
    isObjectValueTrue(dirtyFields),
    isValid,
    address,
    !(isObjectValueTrue(dirtyFields) && isValid && address.length > 0)
  );

  return (
    <>
      <article>
        {isCorrectNetwork && <PContainer className="network-error">{t(`common.change_metamask_network`)} </PContainer>}
        {isMatchAccount && <AccountErrorText>{t(`home.header_account_error`)}</AccountErrorText>}

        <section className="shop-cont">
          <div className="nft-detail-wrap">
            <Link to="/shop/product" className="back">
              <strong>제품 NFT 교환하기</strong>
              <p>
                당신이 파파그라운드 게임을 통해 모은 제품 NFT를 교환할 수 있는 공간입니다.
                <br />
                허용된 횟수 내에서 마음껏 실물 상품 또는 쿠폰으로 교환하세요!
              </p>
            </Link>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="exchange">
                <div className="exchange-box product">
                  <h3 className="small-tit">교환 제품 정보</h3>
                  <div className="cont">
                    <div className="flex start">
                      <figure>
                        <img src={img_product03} alt="" />
                      </figure>
                      <ul className="info">
                        <li>
                          <strong>제품명</strong>
                          <div>가지 클리어링 필링 패드 토너</div>
                        </li>
                        <li>
                          <strong>등급</strong>
                          <div className="tag orange">Legendary</div>
                        </li>
                        <li>
                          <strong>잔여횟수/허용횟수</strong>
                          <div>0/5</div>
                        </li>
                        <li>
                          <strong>배송 신청 수량</strong>
                          <div className="quantity">
                            {count}
                            <a className="btn-up" onClick={onIncreaseCount} />
                            <a className={classNames('btn-down', { on: count !== 1 })} onClick={onDecreaseCount} />
                          </div>
                        </li>
                      </ul>
                    </div>
                    <a className="btn-round">제품정보</a>
                  </div>
                </div>
                <ApplicationInformationBox register={register} errors={errors} />
                <ShippingInformationBox
                  register={register}
                  openModal={openModal}
                  address={address}
                  setAddress={setAddress}
                  copyValue={copyValue}
                  errors={errors}
                />
                <PaymentInformationBox />
                <p className="desc">
                  위 주문 내역을 확인하였으며, 본인은 개인정보 이용 및 제공과 결제 방식에 동의합니다.
                </p>
                <SubmitBtn
                  className="btn-round disabled"
                  type="submit"
                  disabled={!(isObjectValueTrue(dirtyFields) && isValid && address.length > 0)}
                  test={!(isObjectValueTrue(dirtyFields) && isValid && address.length > 0)}
                  // style={{ opci }}
                >
                  교환 신청하기
                </SubmitBtn>
              </div>
            </form>
          </div>
        </section>
        <ModalPortal>
          <AddressModal closeModal={closeModal} setAddress={setAddress} setValue={setValue} />
        </ModalPortal>

        <InfoModalPortal>
          <RequireLoginModal closeModal={infoCloseModal} skip={true} />
        </InfoModalPortal>
      </article>
    </>
  );
};

export default OrderCheckoutPage;
