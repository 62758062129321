import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from '../pages/Home';
import { routes } from './contants';
import AboutPage from '../pages/About';
import GroundPage from '../pages/Ground';
import MemberShipPage from '../pages/MemberShip';
import ShopPage from '../pages/Shop';
import MembershipDetailPage from '../components/MemberShipDetail';
import OrderCheckoutPage from '../pages/Order/Checkout';

export const PublicRouter = () => {
  const { HOME, ABOUT, GROUND, MEMBERSHIP, SHOP, ORDER_CHECKOUT } = routes;
  return (
    <>
      <Switch>
        <Route exact path={HOME} component={HomePage} />
        <Route exact path={ABOUT} component={AboutPage} />
        <Route exact path={GROUND} component={GroundPage} />
        <Route exact path={MEMBERSHIP} component={MemberShipPage} />
        <Route path="/shop/membership/:id" component={MembershipDetailPage} />
        <Route path={ORDER_CHECKOUT} component={OrderCheckoutPage} />
        <Route path={SHOP} component={ShopPage} />
      </Switch>
    </>
  );
};
