import sns01 from '../../assets/images/ico_sns01.png'
import sns02 from '../../assets/images/ico_sns02.png'
import sns03 from '../../assets/images/ico_sns03.png'
import sns04 from '../../assets/images/ico_sns04.png'
import { useLocation } from 'react-router-dom';

const isMembershipPage = [{ img: sns01, url: ''}];
const otherPages = [{ img: sns01, url: ''}, { img: sns02, url: '' }, { img: sns03, url: '' }, { img: sns04, url: ''}];


const Footer = () => {

    const location = useLocation()
    const footerLink = location.pathname === '/membership' ? isMembershipPage : otherPages;
    return (
        <>
            <footer>
                <div className="inner-box">
                    <strong>Official Communities</strong>
                    <ul>
                        {footerLink.map((link, i) => (
                          <li key={i}>
                              <a href={link.url} target="_blank"><img src={link.img} alt='' /></a>
                          </li>
                        ))}
                    </ul>
                </div>
                <p className="copy">©2022 COSTORY CO., LTD. All rights reserved.</p>
            </footer>
        </>
    )
}

export default Footer;