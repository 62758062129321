import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
if (process.env.NODE_ENV !== 'development') console.log = () => {};
ReactDOM.render(
  <BrowserRouter>
    <RecoilRoot>
      <Suspense fallback={<div>Loading... </div>}>
        <App />
      </Suspense>
    </RecoilRoot>
  </BrowserRouter>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
