import { useState } from 'react';
import dummy_img_01 from '../../../assets/images/shop_1.gif';
import dummy_img_02 from '../../../assets/images/shop_2.gif';
import dummy_img_03 from '../../../assets/images/shop_3.gif';
import dummy_img_04 from '../../../assets/images/shop_4.gif';
import dummy_img_05 from '../../../assets/images/shop_5.gif';
import dummy_img_06 from '../../../assets/images/shop_6.gif';
import dummy_img_07 from '../../../assets/images/shop_7.gif';
import MemberShipNftLists from '../../MembershipNftLists';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import MembershipDetailPage from '../../MemberShipDetail';
import { useTranslation } from 'react-i18next';

const Membership = () => {
  const { t } = useTranslation();
  const [dummyMembershipNftLists, setDummyNftLists] = useState([
    {
      id: 1,
      imgUrl: dummy_img_01,
      type: 'Type A+',
      name: 'SHERPA CLUB A+',
      tagBgColor: 'bg-black',
      tagTextColor: false,
      membershipName: 'MEMBER #24',
    },
    {
      id: 2,
      imgUrl: dummy_img_02,
      type: 'Type A',
      name: 'SHERPA CLUB A',
      tagBgColor: 'bg-yellow',
      tagTextColor: true,
      membershipName: 'MEMBER #112',
    },
    {
      imgUrl: dummy_img_02,
      type: 'Type A',
      name: 'SHERPA CLUB A',
      tagBgColor: 'bg-yellow',
      tagTextColor: true,
      membershipName: 'MEMBER #112',
    },
    {
      id: 3,
      imgUrl: dummy_img_03,
      type: 'Type Z',
      name: 'SHERPA CLUB Z',
      tagBgColor: 'bg-orange',
      tagTextColor: true,
      membershipName: 'MEMBER #110',
    },
    {
      id: 4,
      imgUrl: dummy_img_04,
      type: 'Type P',
      name: 'SHERPA CLUB P',
      tagBgColor: 'bg-purple',
      tagTextColor: false,
      membershipName: 'MEMBER #240',
    },
    {
      id: 5,
      imgUrl: dummy_img_05,
      type: 'Type P',
      name: 'SHERPA CLUB P',
      tagBgColor: 'bg-green',
      tagTextColor: false,
      membershipName: 'MEMBER #241',
    },
    {
      id: 6,
      imgUrl: dummy_img_06,
      type: 'Type P',
      name: 'SHERPA CLUB P',
      tagBgColor: 'bg-white',
      tagTextColor: true,
      membershipName: 'MEMBER #242',
    },
    {
      id: 7,
      imgUrl: dummy_img_07,
      type: 'Type P',
      name: 'SHERPA CLUB P',
      tagBgColor: 'bg-deep-red',
      tagTextColor: false,
      membershipName: 'MEMBER #251',
    },
  ]);
  let { path, url } = useRouteMatch();

  return (
    <>
      <div className="cont-box">
        <div className="cont on">
          <p className="desc">{t(`shop.main_desc_01`)}</p>
          <ul className="membership-list">
            {dummyMembershipNftLists.map((item, i) => (
              <MemberShipNftLists {...item} key={i} />
            ))}
          </ul>
        </div>
      </div>

      {/*<div className="cont-box empty">*/}
      {/*  <div className="cont on">*/}
      {/*    <strong className="ico-empty">Membership NFT가 없습니다.</strong>*/}
      {/*    <p>공식 사이트 또는 NFT 마켓플레이스에서 구매를 진행해 주세요.</p>*/}
      {/*    <div className="btn-box">*/}
      {/*      <a className="btn-line green">공식사이트에서 구매</a>*/}
      {/*      <a className="btn-line green">opensea에서 구매</a>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};

export default Membership;
