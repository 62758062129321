import img_typelist02 from '../../assets/images/img_typelist02.png';
import { cursorPointerStyle } from '../../styles';

const MembershipPageNftMobileLists = ({
  idx,
  name,
  grade,
  black,
  meterial,
  edge,
  url,
  paparecipe,
  papaground,
  brandnew,
  sale,
  description,
  tokenURI,
  limit,
  aboveground,
}) => {
  return (
    <>
      <li>
        <figure>
          <img src={url} alt="" />
          {/*<span className="tag bg-rainbow color-b">Type AAA+</span>*/}
        </figure>
        <div className="text">
          <strong className="list-tit">{name}</strong>
          <div className="flex">
            <span>Amount</span>
            <strong>
              <em>{limit}</em>
            </strong>
          </div>
          <p className="desc" />
          {/*<p className="alert">혜택 미공개</p>*/}
          <ul>
            <li>
              <span>파파레서피, ABG</span>
              <strong>기본혜택 {paparecipe.toString()}배</strong>
            </li>
            <li>
              <span>파파그라운드</span>
              <strong>기본혜택 {papaground.toString()}배</strong>
            </li>
            <li>
              <span>어보브그라운드</span>
              <strong>{aboveground}</strong>
            </li>
            {brandnew && (
              <li>
                <span>파파레서피 신제품</span>
                <strong>자동 구독</strong>
              </li>
            )}
          </ul>
        </div>
        {sale ? (
          <a className="btn-purchase" style={cursorPointerStyle}>
            MINT
          </a>
        ) : (
          <a style={{ opacity: 0.5, cursor: 'not-allowed' }} className="btn-purchase">
            MINT
          </a>
        )}
      </li>
    </>
  );
};

export default MembershipPageNftMobileLists;
