import styled from 'styled-components';

const LiWrapper = styled.li`
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  border-radius: 24px;
  box-shadow: 0 20px 50px 0rgba (71, 77, 70, 0.05);
  -webkit-box-shadow: 0 20px 50px 0 rgb(71 77 70 / 5%);
  -ms-box-shadow: 0 20px 50px 0 rgba(71, 77, 70, 0.05);
  border: solid 1px #e0e4e0;
  background: #fbfbfb;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
`;

const MembershipDetailSlider = ({ img, type, title, desc, benefitOne, benefitTwo, benefitThree, benefitFour }) => {
  return (
    <>
      <LiWrapper>
        <figure>
          <img src={img} alt="" />
          {/*<span className="tag bg-black">{type}</span>*/}
        </figure>
        <div className="text">
          <strong className="list-tit">{title}</strong>
          <p className="desc">{desc}</p>
          <ul>
            <li>
              <span>파파레서피, ABG</span>
              <strong>기본혜택 3배</strong>
            </li>
            <li>
              <span>파파그라운드</span>
              <strong>기본혜택 1.5배</strong>
            </li>
            <li>
              <span>어보브그라운드</span>
              <strong>VIP Access</strong>
            </li>
            <li>
              <span>파파레서피 신제품</span>
              <strong>자동 구독</strong>
            </li>
          </ul>
        </div>
      </LiWrapper>
    </>
  );
};

export default MembershipDetailSlider;
