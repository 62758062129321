import DaumPostcode from 'react-daum-postcode';
import styled from 'styled-components';
const AWrapper = styled.a`
  position: relative;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  margin-bottom: 10px;
`;

const AddressModal = ({ closeModal, setAddress, setValue }) => {
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    setAddress(data.address);
    setValue('detailedAddress', '', {
      shouldDirty: false,
    });
    closeModal();
  };

  return (
    <>
      <div>
        <AWrapper className="pop-close" onClick={closeModal}>
          Close
        </AWrapper>
        <DaumPostcode onComplete={handleComplete} />
      </div>
    </>
  );
};

export default AddressModal;
