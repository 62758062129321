import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import Membership from '../../components/Router/Membership';
import ProductNFT from '../../components/Router/Product';
import History from '../../components/Router/History';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  accountInfoAtom,
  isCorrectNetworkAtom,
  isLoginAtom,
  isMatchAccountAtom,
  pathnameInfoAtom,
} from '../../store/atom';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { cursorPointerStyle } from '../../styles';
import useInput from '../../hooks/useInput';
import { getMyInfo, updateImage, updateProfile } from '../../api/account';
import useModal from '../../hooks/useModal';
import RequireLoginModal from '../../components/Modal/RequireLogin';
import { AccountErrorText, PContainer } from '../Home';
import { Trans, useTranslation } from 'react-i18next';
const ArticleWrapper = styled.article`
  .profile-img {
    border-radius: 50%;
    width: 54px;
    height: 54px;
  }
  .profile-address {
    overflow: inherit;
  }

  .btn-link {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      opacity: 0.5 !important;
    }
  }

  .btn-share {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      opacity: 0.5 !important;
    }
  }

  @media (max-width: 1280px) {
    .profile-img {
      max-width: none;
      width: 54px;
      height: 54px;
    }
  }
`;

const ShopPage = () => {
  const [name, onChangeName, setName] = useInput('');
  const membershipMatch = useRouteMatch('/shop/membership');
  const productMatch = useRouteMatch('/shop/product');
  const historyMatch = useRouteMatch('/shop/history');
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [isEditMode, setIsEditMode] = useState(false);
  const inputOpenImageRef = useRef(null);
  const [pathnameInfo, setPathnameInfo] = useRecoilState(pathnameInfoAtom);
  const { ModalPortal, openModal, closeModal } = useModal();
  const history = useHistory();
  const [url, setUrl] = useState('');
  const isCorrectNetwork = useRecoilValue(isCorrectNetworkAtom);
  const isMatchAccount = useRecoilValue(isMatchAccountAtom);
  const { t } = useTranslation();

  const handleImageClick = () => {
    if (inputOpenImageRef && inputOpenImageRef.current) {
      inputOpenImageRef.current.click();
    }
  };

  const onUpdateProfile = async () => {
    const body = { name, url };
    try {
      const result = await updateProfile(body);
      setAccountInfo({
        ...accountInfo,
        name,
        profile: url,
      });
      setIsEditMode(false);
    } catch (err) {
      alert('에러');
    }
  };

  const handleImageUpload = async (e) => {
    if (!e.target.files) {
      return;
    }
    const formData = new FormData();
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    formData.append('file', e.target.files[0]);

    try {
      const result = await updateImage(formData, config);
      setUrl(result?.success[0].thumbnail.url);
    } catch (err) {
      alert('에러');
      // errorOpenModal();
    }
  };

  const onSaveName = async () => {
    setIsEditMode(false);
  };

  const bootstrap = async () => {
    try {
      await getMyInfo();
      setUrl(accountInfo?.profile);
      setName(accountInfo?.name);
    } catch (err) {
      openModal();
    }
  };

  useEffect(() => {
    bootstrap();
    setPathnameInfo({ text: 'SHOP', route: '/shop/membership', isHashRoute: false });
  }, [accountInfo.name, accountInfo.profile]);

  return (
    <>
      <ArticleWrapper>
        {isCorrectNetwork && <PContainer className="network-error">{t(`common.change_metamask_network`)}</PContainer>}
        {isMatchAccount && <AccountErrorText>{t(`home.header_account_error`)}</AccountErrorText>}

        <section className="shop-cont">
          <div className="user-wrap">
            <div className={classNames({ 'user-info': true, edit: isEditMode })}>
              <figure className="pic" onClick={handleImageClick} style={cursorPointerStyle}>
                <img className="profile-img" src={url} />
              </figure>
              <div className="text">
                {isEditMode ? (
                  <>
                    <div className="edit-box">
                      <span className="input">
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => {
                            if (name.length >= 10) {
                              return;
                            }
                            setName(e.target.value);
                          }}
                          maxLength={10}
                        />
                      </span>
                      <div className="btn-small">
                        <a className="btn-round full" onClick={onUpdateProfile} style={cursorPointerStyle}>
                          Save
                        </a>
                        <a
                          className="btn-round line"
                          style={cursorPointerStyle}
                          onClick={() => {
                            setIsEditMode(false);
                          }}
                        >
                          Cancel
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <strong className="name">
                    {name} 님
                    <a
                      className="btn-edit"
                      style={cursorPointerStyle}
                      onClick={() => {
                        setName(accountInfo?.name);
                        setIsEditMode(true);
                      }}
                    >
                      <i>Edit</i>
                    </a>
                  </strong>
                )}
                <span className="profile-address">{accountInfo?.address}</span>
                {/*<div className="btn">*/}
                {/*  <a className="btn-link">*/}
                {/*    <span>P2E게임과 연동하기</span>*/}
                {/*  </a>*/}
                {/*  <a className="btn-share">*/}
                {/*    <span>공유하기</span>*/}
                {/*  </a>*/}
                {/*</div>*/}
              </div>
            </div>
            <div className="notice-box">
              <strong>ABG Town</strong>
              <p>
                <Trans i18nKey={'shop.main_desc_02'} />
              </p>
            </div>
          </div>
          <ul className="tab">
            <li className={classNames({ curr: membershipMatch !== null })}>
              <Link to="/shop/membership">Membership</Link>
            </li>
            <li className={classNames({ curr: productMatch !== null })}>
              <Link to="/shop/product">Product NFT</Link>
            </li>
            {/*<li className={classNames({ curr: historyMatch !== null })}>*/}
            {/*  <Link to="/shop/history">History</Link>*/}
            {/*</li>*/}
          </ul>

          <Switch>
            <Route exact path="/shop/membership">
              <Membership />
            </Route>

            <Route exact path="/shop/product">
              <ProductNFT />
            </Route>

            <Route exact path="/shop/history">
              <History />
            </Route>
          </Switch>
        </section>
        <input
          type="file"
          accept="image/jpeg, image/png"
          style={{ display: 'none' }}
          ref={inputOpenImageRef}
          onChange={handleImageUpload}
        />
      </ArticleWrapper>
      <ModalPortal>
        <RequireLoginModal closeModal={closeModal} skip={true} />
      </ModalPortal>

      <footer>
        <p className="copy">©2022 COSTORY CO., LTD. All rights reserved.</p>
      </footer>
    </>
  );
};

export default ShopPage;
