import { APICore } from './index';
import axios from 'axios';
const api = new APICore();

const accountDomain = '/account';
const mediaDomain = '/media/';

export const signIn = async (address) => {
  return api.create(`${accountDomain}/signin`, { address }).then((resp) => resp.data.data);
};

export const logOut = async () => {
  return api.create(`${accountDomain}/signout`).then((resp) => resp.data.data);
};

export const getMyInfo = async () => {
  return api.get('/account/authenticated').then((resp) => resp.data.data);
};

export const verifyNonce = async (body, token) => {
  return axios
    .post(`/account/nonce/verify`, body, {
      headers: {
        Authentication: token,
      },
    })
    .then((resp) => resp.data.data);
};

export const updateImage = async (body, config) => {
  return await api.create(`${mediaDomain}/files?type=media`, body, config).then((resp) => resp.data.data);
};

export const updateProfile = async (body) => {
  return api.create(`${accountDomain}`, body).then((resp) => resp.data);
};
