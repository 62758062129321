const localeLangsUtil = (text) => {
  let result;
  switch (text) {
    case "KOR":
      result = "ko";
      break;
    case "ENG":
      result = "en";
      break;
  }
  return result;
};

export { localeLangsUtil };
