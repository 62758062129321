import img_ground from '../../assets/images/img_ground.jpg';
import img_ground_m from '../../assets/images/img_ground-m.jpg';
import img_papaground01 from '../../assets/images/img_papaground01.jpg';
import img_papaground02 from '../../assets/images/img_papaground02.jpg';
import img_papaground03 from '../../assets/images/img_papaground03.jpg';
import img_papaground04 from '../../assets/images/img_papaground04.jpg';
import img_papaground05 from '../../assets/images/img_papaground05.jpg';
import img_papaground06 from '../../assets/images/img_papaground06.jpg';
import img_papaground07 from '../../assets/images/img_papaground07.jpg';
import img_papaground08 from '../../assets/images/img_papaground08.jpg';
import img_papaground09 from '../../assets/images/img_papaground09.jpg';
import img_papaground10 from '../../assets/images/img_papaground10.jpg';
import img_papaground11 from '../../assets/images/img_papaground11.jpg';
import img_papaground12 from '../../assets/images/img_papaground12.jpg';

import img_ground_item from '../../assets/images/img_ground-item.png';
import img_ground_nft from '../../assets/images/img_ground-nft.png';
import img_ground_nft_m from '../../assets/images/img_ground-nft-m.png';

import useModal from 'hooks/useModal';
import NotifyModal from 'components/Modal/NotifyModal';
import { cursorPointerStyle } from '../../styles';
import Slider from 'react-slick';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../lib/util';
import { useRecoilValue } from 'recoil';
import { currentLangAtom, isCorrectNetworkAtom, isMatchAccountAtom } from '../../store/atom';
import { useEffect, useCallback } from 'react';
import { AccountErrorText, PContainer } from '../Home';

const AWarpper = styled.a`
  width: 205px !important;

  @media (max-width: 1280px) {
    width: 205px !important;
    margin-left: -100px !important;
  }
`;

const SectionWrapper = styled.section`
  box-sizing: border-box;
`;

const SliderContainer = styled(Slider)`
  .slick-list {
    overflow: initial !important;
  }

  .slick-track {
    width: 25000px !important;
  }
  .slick-slide {
    width: 848px !important;
    margin: 0 50px !important;
  }
  .slick-active {
    li {
      opacity: 1 !important;
    }
  }

  @media (max-width: 1280px) {
    .slick-slide {
      width: 280px !important;
      margin: 0 14.4px !important;
    }
  }
`;

const GroundPage = () => {
  const { ModalPortal, openModal, closeModal } = useModal();
  const settings2 = {
    // dots: false,
    // infinite: true,
    // speed: 500,
    // slidesToScroll: 2,
    centerMode: true,
    centerPadding: '178px',
    slidesToShow: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '5px',
          slidesToShow: 1,
        },
      },
    ],
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const { t } = useTranslation();
  const currentLanguage = useRecoilValue(currentLangAtom);
  const isCorrectNetwork = useRecoilValue(isCorrectNetworkAtom);
  const isMatchAccount = useRecoilValue(isMatchAccountAtom);

  return (
    <>
      <article>
        {isCorrectNetwork && <PContainer className="network-error">{t(`common.change_metamask_network`)}</PContainer>}
        {isMatchAccount && <AccountErrorText>{t(`home.header_account_error`)}</AccountErrorText>}

        <section className="ground-kv">
          <div className="inner-box">
            <div className="text">
              <strong className="line">
                {t(`ground.main_title`)} <i className="br">{t(`ground.main_title_sub`)}</i>
              </strong>
              <p>{t(`ground.main_desc_25`)}</p>
              <p>
                {t(`ground.main_desc_01`)}
                <br />
                {t(`ground.main_desc_02`)}
              </p>
              <span>{t(`ground.main_desc_03`)}</span>
              {currentLanguage === 'KOR' ? (
                <a onClick={openModal} className="btn-line" style={cursorPointerStyle}>
                  {t(`ground.main_desc_24`)}
                </a>
              ) : (
                <AWarpper onClick={openModal} className="btn-line" style={cursorPointerStyle}>
                  {t(`ground.main_desc_24`)}
                </AWarpper>
              )}
            </div>
          </div>
          {/*<figure>*/}
          {/*  <span className="pc">*/}
          {/*    <img src={img_ground} alt="" />*/}
          {/*  </span>*/}
          {/*  <span className="mobile">*/}
          {/*    <img src={img_ground_m} alt="" />*/}
          {/*  </span>*/}
          {/*</figure>*/}
        </section>

        <SectionWrapper className="ground01">
          <h2 className="tit">{t(`ground.main_desc_04`)}</h2>
          <p className="desc">
            {t(`ground.main_desc_05`)}
            <i className="br">{t(`ground.main_desc_06`)}</i>
          </p>
          <div className="ground-slide">
            <ul>
              <SliderContainer {...settings2}>
                <li className="slider-lists">
                  <img src={img_papaground01} alt="" />
                </li>
                <li>
                  <img src={img_papaground02} alt="" />
                </li>
                <li>
                  <img src={img_papaground03} alt="" />
                </li>
                <li>
                  <img src={img_papaground04} alt="" />
                </li>
                <li>
                  <img src={img_papaground05} alt="" />
                </li>
                <li>
                  <img src={img_papaground06} alt="" />
                </li>
                <li>
                  <img src={img_papaground07} alt="" />
                </li>
                <li>
                  <img src={img_papaground08} alt="" />
                </li>
                <li>
                  <img src={img_papaground09} alt="" />
                </li>
                <li>
                  <img src={img_papaground10} alt="" />
                </li>
                <li>
                  <img src={img_papaground11} alt="" />
                </li>
                <li>
                  <img src={img_papaground12} alt="" />
                </li>
              </SliderContainer>
            </ul>
          </div>
        </SectionWrapper>

        <section className="ground02">
          <div className="inner-box flex">
            <figure>
              <img src={img_ground_item} alt="" />
            </figure>
            <div className="text">
              <h2 className="tit white text-l">
                {t(`ground.main_desc_07`)} <i className="br">{t(`ground.main_desc_08`)}!</i>
              </h2>
              <p className="desc">{t(`ground.main_desc_09`)}</p>
              <p className="desc">{t(`ground.main_desc_10`)}</p>
              <p className="desc">{t(`ground.main_desc_11`)}</p>
            </div>
          </div>
        </section>

        <section className="ground03">
          <div className="inner-box flex">
            <div className="text">
              <h2 className="tit white text-l">
                {t(`ground.main_desc_12`)} <i className="br">{t(`ground.main_desc_13`)}</i>
              </h2>
              <p className="desc">
                {t(`ground.main_desc_14`)}
                <br />
                {t(`ground.main_desc_15`)}
              </p>
              <p className="desc">
                {t(`ground.main_desc_16`)}
                <br />
                {t(`ground.main_desc_17`)}
                <br />
                {t(`ground.main_desc_18`)}
                <br />
                {t(`ground.main_desc_19`)}
              </p>
              <p className="desc">
                {t(`ground.main_desc_20`)}
                <br />
                {t(`ground.main_desc_21`)}
              </p>
            </div>
            <figure>
              <span className="pc">
                <img src={img_ground_nft} alt="" />
              </span>
              <span className="mobile">
                <img src={img_ground_nft_m} alt="" />
              </span>
            </figure>
          </div>
        </section>

        <section className="ground04">
          <strong>
            {t(`ground.main_desc_22`)}
            <i className="mobile-br">{t(`ground.main_desc_23`)}</i>
          </strong>
          <a style={cursorPointerStyle} onClick={openModal} className="btn-line green">
            {t(`ground.main_desc_24`)}
          </a>
        </section>

        <ModalPortal>
          <NotifyModal closeHandler={closeModal} />
        </ModalPortal>
      </article>
      <footer>
        <p className="copy">©2022 COSTORY CO., LTD. All rights reserved.</p>
      </footer>
    </>
  );
};

export default GroundPage;
