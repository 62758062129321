import { APICore } from './index';

const api = new APICore();
const editionDomain = '/apps/editions';
const rpcDomain = '/apps/blockchain';
const mintDomain = '/apps/mint';
const whiteListDomain = '/apps/whitelists';
const nftDomain = '/nfts';
export const getNftItems = async (address) => {
  return api.get('/nft/available');
};

export const getMembershipNftItems = async () => {
  return api.get(`${nftDomain}/membership/items`).then((resp) => resp.data.data.membership);
};

export const registerEmail = async (body) => {
  return api.create('/apps/games/register', body).then((resp) => resp.data.data);
};

export const getPeeksInfo = async () => {
  return api.get('/apps/peeks').then((resp) => resp.data.data.peeks);
};

export const getRpcData = async () => {
  return api.get(`${rpcDomain}`).then((resp) => resp.data.data.blockchain);
};
