import { cursorPointerStyle } from '../../styles';
import { useTranslation } from 'react-i18next';

const InfoModal = ({ closeModal, text }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="popup-wrap" style={{ display: 'block' }}>
        <div className="dim" />

        <div className="pop-type01 connect-error">
          <p className="ico-error">{text}</p>
          <a style={cursorPointerStyle} onClick={closeModal} className="btn-round">
            {t(`common.back`)}
          </a>
        </div>
      </div>
    </>
  );
};

export default InfoModal;
